import React, { useState, useEffect, createContext, useContext } from "react";
import { getAuth } from "firebase/auth";

/*
  Verifies that the user is signed in prior to server refresh. This allows 
  pages to remember if the user is signed in without requiring a refresh.
*/ 
const useUserInternal = () => {

  const auth = getAuth();

  const [userState, setUserState] = useState({
    user: auth.currentUser,
    isLoading: auth.currentUser === null ? true : false,
    error: null,
  });

  const { user, isLoading, error } = userState;
  const isSignedIn = user !== null;
  const userId = isSignedIn ? user.uid : undefined;

  useEffect(() => {
    const onChange = (currentUser) => {
      setUserState({ user: currentUser, isLoading: false, error: null });
    }

    const onError = (error) => {
      console.error(error);
      setUserState({ user: null, isLoading: false, error});
    }
    const unsubscribe = auth.onAuthStateChanged(onChange, onError);

    return unsubscribe;
  }, [])

  return {
    user,
    isLoading,
    isSignedIn,
    error,
  }
};

const UserContext = createContext(null);

// Context provider 
const UserProvider = ({children}) => {
    const userState = useUserInternal();
    return <UserContext.Provider value={userState}>
        {children}
    </UserContext.Provider>
}

const useUser = () => {
    const userState = useContext(UserContext);
    return userState;
}

export { UserContext, UserProvider, useUser }