import './styles.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { setUser } from '../../services/userProfile';
import gxLogo from '../../assets/icons/Channel_Logos/gx.png';
import { Translator, Translate } from 'react-auto-translate';
import TranslateOpt from '../../components/Translate/index.js';
import { apiKey } from '../../../config.js';
import { SIGN_IN_ROUTE } from 'pages/Routes';
import SignupNavbar from 'components/SignupNavbar';
import Footer from 'components/Footer';
import { Formik, Form } from 'formik';
import { FormikField } from '../../components/FormikField';
import { db } from '../../firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import useUserInternal, { UserContext, useUser } from '../../hooks/use-user';
import { useLanguage } from 'components/Translate/LanguageContext';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { passwordSchema } from 'util/schema/password';
import { useHistory } from 'react-router-dom';
import CHECK_MARK from '../../assets/icons/Reset_Password/check-mark-icon.png';
import RESET_SUCCESS from '../../assets/icons/Reset_Password/password-reset-success.png';
import { getAuth, confirmPasswordReset, signOut } from 'firebase/auth';
import PasswordReset from 'components/SuccessScreens/PasswordReset';
import SHOW_PASSWORD from '../../assets/icons/Profile_Page/show-password.png';
import DONT_SHOW_PASSWORD from '../../assets/icons/Profile_Page/dont-show-password.png';

// This page is used to reset a users password after they submit a password reset request and are sent here upon clicking the email link they receive.
const ResetPassword = () => {
  const [error, setError] = useState('');
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [users, setUsers] = useState([]);
  const { language, changeLanguage } = useLanguage();
  const [reset, setReset] = useState(false); // Determines whether to show the screen to change password or the password reset confirmation screen
  const auth = getAuth();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const onPasswordFocus = () => {
    setPasswordFocused(true);
  };

  const onPasswordBlur = () => {
    setPasswordFocused(false);
  };

  // const signinURL = () => {
  //   window.location.href = SIGN_IN_ROUTE;
  //   setReset(false);
  // };

  const resetPassword = async (pass) => {
    const url = window.location.href;

    // Split the URL by '?' to separate the base URL and query parameters
    const urlParts = url.split('?');

    // Check if there are query parameters
    if (urlParts.length === 2) {
      // Split the query parameters by '&' to get individual key-value pairs
      const queryParams = urlParts[1].split('&');

      // Iterate through each key-value pair to find the confirmation code
      for (const param of queryParams) {
        const [key, value] = param.split('=');
        if (key === 'oobCode') {
          // 'oobCode' is the parameter name for the confirmation code
          // console.log(value);
          // console.log(decodeURIComponent(value));
          await confirmPasswordReset(auth, value, pass) // Need to put auth as first argument even though documentation doesn't show it
            .then(() => {
              // console.log('Reset password');
              setError('');
              signOut(auth) // Sign out the user so they can sign in with their new password
                .then(() => {
                  setReset(true);
                })
                .catch(() => {
                  // console.log("Couldn't log out user");
                  setError('Error logging out');
                });
            })
            .catch((error) => {
              // console.log('Whoops');
              console.error(error.message);
              if (error.code === 'auth/expired-action-code') {
                setError('Password reset code expired');
              } else if (error.code === 'auth/invalid-action-code') {
                setError('Invalid password reset code');
              } else if (error.code === 'auth/weak-password') {
                setError('Password too weak please try again');
              } else {
                setError('Unable to reset password');
              }
            });
          return;
        }
      }
    }
    // Return null if confirmation code is not found in the URL
    // console.log('rip');
    setError('Error getting confirmation code please send another reset request.');
    return null;
  };

  const passwordChecker = (val = '') => {
    // Enforces at least one uppercase, one lowercase, one number, one special character, no spaces, and at least 8 characters
    const validPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W])(?!.*\s).{8,}/;
    if (val.match(validPassword)) {
      // console.log('Yay it works');
      return true;
    } else {
      // console.log("Doesn't work");
      return false;
    }
  };

  const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(<Translate>Required</Translate>)
      .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
      .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
      .test({
        name: 'passwordChecker',
        message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
        test: passwordChecker,
      }),
    confirmNewPassword: Yup.string()
      .required(<Translate>Required</Translate>)
      .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
      .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
      .test({
        name: 'passwordChecker',
        message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
        test: passwordChecker,
      })
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  //
  const onSubmit = (values) => {
    resetPassword(values.newPassword);
  };

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!reset) {
    return (
      <Translator from='en' to={language} googleApiKey={apiKey}>
        <div className='signin-page-container'>
          <SignupNavbar />
          <div className='signin-boxi'>
            <p className='new-password-subheaderi'>Enter your new password below.</p>
            <Formik
              initialValues={{ newPassword: '', confirmNewPassword: '' }}
              onSubmit={onSubmit}
              validationSchema={resetPasswordSchema}
              validateOnChange={true}
            >
              {(props) => {
                return (
                  <Form className='form-container'>
                    <div className='password-field-container'>
                      <FormikField
                        label={<Translate>New Password*</Translate>}
                        name='newPassword'
                        type={showNewPassword === false ? 'password' : 'text'}
                        onBlur={onPasswordBlur}
                        onFocus={onPasswordFocus}
                        onChange={props.handleChange}
                        value={props.values.newPassword}
                      />
                      <img
                        src={showNewPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                        alt=''
                        className='password-eye-icon'
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </div>
                    {isWideScreen && (
                      <PasswordTooltip isFocused={passwordFocused} password={props.values.newPassword} />
                    )}
                    <div className='password-field-container'>
                      <FormikField
                        label={<Translate>Confirm New Password*</Translate>}
                        name='confirmNewPassword'
                        type={showConfirmPassword === false ? 'password' : 'text'}
                        onBlur={onPasswordBlur}
                        onFocus={onPasswordFocus}
                        onChange={props.handleChange}
                        value={props.values.confirmNewPassword}
                      />
                      <img
                        src={showConfirmPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                        alt=''
                        className='password-eye-icon'
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      />
                    </div>
                    <button className='gn-signup-buttoni' type='submit'>
                      <Translate>Reset Password</Translate>
                    </button>
                    <div className='gn-sign_up-error'>
                      <Translate>{error}</Translate>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <Footer />
        </div>
      </Translator>
    );
  } else {
    return <PasswordReset setReset={setReset} />;
  }
};

export default ResetPassword;
