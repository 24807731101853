
import React from 'react';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';


function DropdownItem(props) {

  return(
    <center><button className='access_button' onClick = {props.onClick}> <Translate>{props.text}</Translate></button></center> 


  );
}



export default DropdownItem;