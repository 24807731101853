import React, { useState } from 'react';
import SignupNavbar from 'components/SignupNavbar';
import { useLanguage } from 'components/Translate/LanguageContext';
import Footer from 'components/Footer';
import { apiKey } from '../../../config.js';
import { SIGN_IN_ROUTE, RESET_PASSWORD_ROUTE } from 'pages/Routes';
import { Translator, Translate } from 'react-auto-translate';
import RESET_SUCCESS from '../../assets/icons/Reset_Password/password-reset-success.png';
import './PasswordReset.scss';

// Component to display a successful password reset, reroutes the user to the sign in page to sign in with their new credentials
const PasswordReset = ({ setReset }) => {
  const { language, changeLanguage } = useLanguage();

  const signinURL = () => {
    window.location.href = SIGN_IN_ROUTE;
    setReset(false);
  };

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='signin-page-container'>
        <SignupNavbar />
        <div className='signin-boxi'>
          <img src={RESET_SUCCESS} alt='' className='email-icon' />
          <p className='reset-header'>
            <Translate>Your password has been reset successfully!</Translate>
          </p>
          <button className='checkmark-button' onClick={() => signinURL()}>
            <Translate>Ok</Translate>
          </button>
        </div>
        <Footer />
      </div>
    </Translator>
  );
};

export default PasswordReset;
