// LanguageContext.js
import React, { createContext, useState, useContext } from 'react';
import ENGLISH from '../../assets/icons/Language_Icons/USA.png';
import SPANISH from '../../assets/icons/Language_Icons/Spain.png';
import KOREAN from '../../assets/icons/Language_Icons/South_Korea.png';
import ARABIC from '../../assets/icons/Language_Icons/Saudi_Arabia.png';
import RUSSIAN from '../../assets/icons/Language_Icons/Russia.png';
import PORTUGUESE from '../../assets/icons/Language_Icons/Portugal.png';
import URDU from '../../assets/icons/Language_Icons/Pakistan.png';
import JAPANESE from '../../assets/icons/Language_Icons/Japan.png';
import ITALIAN from '../../assets/icons/Language_Icons/Italy.png';
import INDONESIAN from '../../assets/icons/Language_Icons/Indonesia.png';
import HAITIAN_CREOLE from '../../assets/icons/Language_Icons/Haiti.png';
import GREEK from '../../assets/icons/Language_Icons/Greece.png';
import GERMAN from '../../assets/icons/Language_Icons/Germany.png';
import FRENCH from '../../assets/icons/Language_Icons/France.png';
import CHINESE from '../../assets/icons/Language_Icons/China.png';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [languageFlag, setLanguageFlag] = useState(localStorage.getItem('flag') || ENGLISH); // Allows the flag to persist between renders
  const [languageSymbol, setLanguageSymbol] = useState(localStorage.getItem('language')?.toUpperCase() || 'EN');
  // Added an object to allow for selection of the proper language and language flag
  const languageData = {
    en: { id: 0, flag: ENGLISH, abbreviation: 'EN', language: 'English' },
    ar: { id: 1, flag: ARABIC, abbreviation: 'AR', language: 'Arabic' },
    'zh-cn': { id: 2, flag: CHINESE, abbreviation: 'ZH-CN', language: 'Chinese (Simplified)' },
    fr: { id: 3, flag: FRENCH, abbreviation: 'FR', language: 'French' },
    de: { id: 4, flag: GERMAN, abbreviation: 'DE', language: 'German' },
    el: { id: 5, flag: GREEK, abbreviation: 'EL', language: 'Greek' },
    ht: { id: 6, flag: HAITIAN_CREOLE, abbreviation: 'HT', language: 'Haitian Creole' },
    id: { id: 7, flag: INDONESIAN, abbreviation: 'ID', language: 'Indonesian' },
    it: { id: 8, flag: ITALIAN, abbreviation: 'IT', language: 'Italian' },
    jp: { id: 9, flag: JAPANESE, abbreviation: 'JP', language: 'Japanese' },
    ko: { id: 10, flag: KOREAN, abbreviation: 'KO', language: 'Korean' },
    pt: { id: 11, flag: PORTUGUESE, abbreviation: 'PT', language: 'Portuguese' },
    ru: { id: 12, flag: RUSSIAN, abbreviation: 'RU', language: 'Russian' },
    es: { id: 13, flag: SPANISH, abbreviation: 'ES', language: 'Spanish' },
    ur: { id: 14, flag: URDU, abbreviation: 'UR', language: 'Urdu' },
  };
  const languageList = Object.values(languageData);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    // Remove window.location.reload() and instead rely on React's re-rendering
  };

  const changeFlag = (newFlag) => {
    setLanguageFlag(newFlag);
    localStorage.setItem('flag', newFlag);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
        languageFlag,
        setLanguageFlag,
        languageSymbol,
        setLanguageSymbol,
        languageList,
        changeFlag,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
