import "./styles.scss";
import React from "react";
import PropTypes from "prop-types";
import { Validation } from "./Validation";
import { classMap } from "../../util/classMap";
import { Translator, Translate } from 'react-auto-translate';

// Test patterns
const LOWER = /[a-z]/;
const UPPER = /[A-Z]/;
const NUMBER = /\d/;
const SYMBOL = /[^a-z0-9\s]/i;

function PasswordTooltip(props) {
  const { className, icon, isFocused, password } = props;

  const classes = classMap({
    "gn-password-tooltip": true,
    "gn-password-tooltip-hidden": !isFocused,
    [className]: true,
  });

  return (
    <div className={classes}>
      <div className="gn-password-tooltip-heading"><Translate>Passwords must have...</Translate></div>

      <Validation icon={icon} isValid={password.length >= 6}>
        <span className="gn-password-tooltip-emphasis">6</span> <Translate>characters
        minimum</Translate>
      </Validation>

      <Validation icon={icon} isValid={LOWER.test(password)}>
        <Translate>One</Translate> <span className="gn-password-tooltip-emphasis"><Translate>lowercase</Translate></span>{" "}
        <Translate>character</Translate>
      </Validation>

      <Validation icon={icon} isValid={UPPER.test(password)}>
        <Translate>One</Translate> <span className="gn-password-tooltip-emphasis"><Translate>uppercase</Translate></span>{" "}
        <Translate>character</Translate>
      </Validation>

      <Validation icon={icon} isValid={NUMBER.test(password)}>
        <Translate>One</Translate> <span className="gn-password-tooltip-emphasis"><Translate>number</Translate></span>
      </Validation>

      

      <div className="gn-password-tooltip-tail" />
    </div>
  );
}

PasswordTooltip.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  password: PropTypes.string,
};

PasswordTooltip.defaultProps = {
  className: "",
  password: "",
};

export { PasswordTooltip };
