import './styles.scss';
import React, { useState, useEffect } from 'react';
import SignupNavbar from 'components/SignupNavbar';
import Footer from 'components/Footer';
import Camera from '../../assets/icons/Profile_Page/camera.png';
import CuteHippo from '../../assets/icons/Profile_Page/cute-hippo.jpg';
import PASSWORD_PERSON from '../../assets/icons/Profile_Page/profile-person.png';
// import PROFILE_ONE from '../../assets/icons/profile-icon-1.png'; These assets are no longer needed
// import PROFILE_TWO from '../../assets/icons/profile-icon-2.png';
// import PROFILE_THREE from '../../assets/icons/profile-icon-3.png';
// import PROFILE_FOUR from '../../assets/icons/profile-icon-4.png';
// import PROFILE_FIVE from '../../assets/icons/profile-icon-5.png';
// import PROFILE_SIX from '../../assets/icons/profile-icon-6.png';
// import PROFILE_SEVEN from '../../assets/icons/profile-icon-7.png';
// import PROFILE_EIGHT from '../../assets/icons/profile-icon-8.png';
// import PROFILE_NINE from '../../assets/icons/profile-icon-9.png';
// import PROFILE_TEN from '../../assets/icons/profile-icon-10.png';
// import PROFILE_ELEVEN from '../../assets/icons/profile-icon-11.png';
import SHOW_PASSWORD from '../../assets/icons/Profile_Page/show-password.png';
import DONT_SHOW_PASSWORD from '../../assets/icons/Profile_Page/dont-show-password.png';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { PasswordTooltip } from 'components/PasswordTooltip';
import { storage } from '../../firebase-config';
import { setUser } from '../../services/userProfile';
import { Redirect, Link } from 'react-router-dom';
import { useFormik, Formik, Form } from 'formik';
import { listAll, ref, getDownloadURL } from 'firebase/storage';
import * as Yup from 'yup';
import { FormikField } from '../../components/FormikField';
import { UserContext, useUser } from '../../hooks/use-user';
import avatar from '../../assets/icons/Sign_In_Page_Icons/user.png';
import { db } from '../../firebase-config';
import { connect } from 'react-redux';
import { collection, doc, getDocs, updateDoc, update, query, where } from 'firebase/firestore';
import { useLanguage } from 'components/Translate/LanguageContext';
import { passwordSchema } from 'util/schema/password';
import {
  getAuth,
  updateProfile,
  updateEmail,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  signOut,
} from 'firebase/auth';
import firebase from 'firebase/app';
import PasswordReset from 'components/SuccessScreens/PasswordReset';

const MyProfile = () => {
  const [isClicked, setIsClicked] = useState(false); // Sets whether to open the picture selector after clicking on the picture picker
  // const profileIcons = [
  //   // List of icons to use(will probably have to delete later and replace with firebase icons)
  //   PROFILE_ONE,
  //   PROFILE_TWO,
  //   PROFILE_THREE,
  //   PROFILE_FOUR,
  //   PROFILE_FIVE,
  //   PROFILE_SIX,
  //   PROFILE_SEVEN,
  //   PROFILE_EIGHT,
  //   PROFILE_NINE,
  //   PROFILE_TEN,
  //   PROFILE_ELEVEN,
  //   CuteHippo,
  // ];
  const [clickedIcons, setClickedIcons] = useState(Array(12).fill(false)); // Array to keep track of whether an icon was clicked on or not
  const [profileIcon, setProfileIcon] = useState(CuteHippo);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [error, setError] = useState('');
  const [reset, setReset] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const mapDispatchProps = {
    setUser,
  };
  const userState = useUser(); //only firebase auth is saved to context
  const [user, setUser] = useState({ name: null, email: null, phone: null, photoURL: '' });
  const auth = getAuth(); //full auth
  // const message = document.querySelector('#message');
  const [message, setMessage] = useState('');
  const [nameUpdate, setNameUpdate] = useState('');
  const [emailUpdate, setEmailUpdate] = useState('');
  const [phoneUpdate, setPhoneUpdate] = useState('');
  const [imageList, setImageList] = useState([]);
  const [selectedProfilePic, setSelectedProfilePic] = useState(null);
  const [currentProfilePic, setCurrentProfilePic] = useState({ currentProfilePic: '' });
  const imageListRef = ref(storage, 'AvatarIcons/');
  const { language } = useLanguage();
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({}); // Object to hold and update only fields that have changed(name, email, phoneNumber)

  const onPasswordFocus = () => {
    setPasswordFocused(true);
  };

  const onPasswordBlur = () => {
    setPasswordFocused(false);
  };

  function clearMessage() {
    setMessage('');
  }

  const handleClickedIcon = (index) => {
    // Falsify every value than make true the icon that was clicked on to apply style
    const newClickedIcons = Array(12).fill(false);
    newClickedIcons[index] = true;
    setClickedIcons(newClickedIcons);
  };

  //get the current user
  // Feel free to change this back later on but I noticed that there were issues getting users data consistently with the email query
  const getCurrentUser = async () => {
    // console.log('getCurrentUser');
    // console.log(auth.currentUser);

    if (auth.currentUser.photoURL == null) {
      //if auth profile pic is null
      auth.currentUser.photoURL = avatar; //set it to default avatar
    }

    setUser({
      name: auth.currentUser.displayName,
      email: auth.currentUser.email,
      phone: auth.currentUser.phoneNumber,
      photoURL: auth.currentUser.photoURL || CuteHippo,
    });
    return;

    // if (user.email === null) {
    //   // Prevents users who initially signed in with a phone number from being queried by a null email value
    //   setUser({
    //     name: auth.currentUser.displayName,
    //     email: auth.currentUser.email,
    //     phone: auth.currentUser.phoneNumber,
    //     photoURL: auth.currentUser.photoURL || CuteHippo,
    //   });
    //   return;
    // }
    // const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   //doc.data() is never undefined for query doc snapshot -- from firestore documentation
    //   let userData = doc.data();
    //   console.log('userdata ', userData);
    //   console.log('Trying phonenumber ', userData.phoneNumber);
    //   console.log('Trying phone ', userData.phone);
    //   let curPhotoURL = userData.photoURL;
    //   if (curPhotoURL == null) {
    //     if (auth.currentUser.photoURL == null) {
    //       //if auth profile pic is null
    //       auth.currentUser.photoURL = avatar; //set it to default avatar
    //     }
    //   }
    //   curPhotoURL = auth.currentUser.photoURL; //set curPhotoURL to the auth/currentUserPhotoURL
    //   setUser({
    //     name: user.name,
    //     email: user.email,
    //     phone: auth.currentUser.phoneNumber,
    //     photoURL: auth.currentUser.photoURL || CuteHippo, // Can change later but this is just in case a user is unable to have their picture stored
    //   });
    //   // console.log('userData', userData);
    // });
  };

  // Updates the users name
  const changeName = async (newName) => {
    let oldName = user.name;

    if (newName.length === 0) {
      // Prevents blank names from being submitted
      return;
    }

    if (oldName === newName) {
      setMessage('');
      setNameUpdate('Name already associated with this account');
      setTimeout(() => setNameUpdate(''), 5000);
      return;
    }
    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email)); //get user based on current auth email, could add check to see if email auth matches database auth later on
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docu) => {
      //there should only be one, but this loop works fine
      //doc.data() is never undefined for query doc snapshot -- from firestore documentation
      const userRef = doc(db, 'users', docu.id);
      let userData = docu.data();
      let newUserData = { ...userData, name: newName };
      try {
        updateDoc(userRef, newUserData);
      } catch (e) {
        // console.log(e);
      }
    });

    updateProfile(auth.currentUser, {
      displayName: newName,
    })
      .then(() => {
        setUser((prevUser) => ({ ...prevUser, name: newName }));
        setNameUpdate('Name updated');
        setTimeout(() => setNameUpdate(''), 5000);
        // setMessage(`Name Changed To ${newName}`);
        setMessage('');
        // console.log('changed name');
        // setTimeout(clearMessage, 2000);
      })
      .catch((error) => {
        if (code.error === 'auth/user-token-expired') {
          // Sometimes a user's session expires so they need to reauthenticate to make any more changes
          setMessage('User token expired please reauthenticate to make any more account changes');
          setTimeout(clearMessage, 5000);
        } else {
          setMessage('Error changing name');
          setTimeout(clearMessage, 5000);
          // console.log('error changing name');
          // console.log(error);
        }
      });
  };

  // Updates the users email
  // This function is a bit more difficult with needing to update both the current auth email and the database email,
  // Left as is for now
  const changeEmail = async (newEmail) => {
    let oldEmail = user.email;

    if (newEmail.length === 0) {
      return;
    }

    if (oldEmail === newEmail) {
      setMessage('');
      setEmailUpdate('Email already associated with this account');
      setTimeout(() => setEmailUpdate(''), 5000);
      return;
    }

    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        // userData.email = email;
        setUser((prevUser) => ({ ...prevUser, email: newEmail }));
        setEmailUpdate('Email Updated');
        setTimeout(() => setEmailUpdate(''), 5000);
        // setMessage(`E-Mail Address Changed To ${newEmail}`);
        // console.log('changed email');
        // setTimeout(clearMessage, 2000);
        setMessage('');
      })
      .catch((error) => {
        if (error.code === 'auth/invalid-email') {
          setMessage('Invalid email please try again');
          setTimeout(clearMessage, 5000);
        } else if (error.code === 'auth/email-already-in-use') {
          setMessage('Email already in use');
          setTimeout(clearMessage, 5000);
        } else if (error.code === 'auth/requires-recent-login') {
          setMessage('Session expired please login again');
          setTimeout(clearMessage, 5000);
        } else {
          setMessage('Error changing email please try again');
          setTimeout(clearMessage, 5000);
          // console.log('error changing email');
          // console.log(error);
        }
      });
  };

  // Country code is set at US, can be updated later on to be whatever code the user's country holds
  const countryCode = '+1';
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [phone, setPhone] = useState('');

  // Phone case variable
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  /*
        Generates a recaptcha in its respected window
    */
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {},
      },
      auth
    );
  };

  /*
        Gets the verification code from the user by requesting the phone number
        and sending the text with Recaptcha and PhoneAuth from Firebase
    */
  const requestOTP = (phoneNumber) => {
    // phoneNumber = countryCode + phoneNumber;
    // At the moment this is for phones with a US country code but feel free to change later on
    phoneNumber = phoneNumber.replace(/\D/g, ''); // Replaces any special characters a user may have used
    // This checks if a user submitted a phone number with a country code and either adds the + sign or the +1
    phoneNumber[0] === '1' ? (phoneNumber = '+' + phoneNumber) : (phoneNumber = countryCode + phoneNumber);
    // console.log('This works :', phoneNumber);

    // if (phoneNumber[0] === "1") {
    //   console.log('Country code')
    //   phoneNumber = '+' + phoneNumber
    // } else
    if (phoneNumber.length === 0) {
      return;
    }

    if (phoneNumber === user.phone) {
      setMessage('');
      setPhoneUpdate('Number already associated with this account');
      setTimeout(() => setPhoneUpdate(''), 5000);
      return;
    }

    setPhone(phoneNumber);
    // console.log('phone number here : ', phoneNumber);
    if (phoneNumber.length >= 11) {
      setExpandForm(true);
      setMessage('Passcode sent to your device(Data rates may apply)');
      generateRecaptcha();
      const provider = new PhoneAuthProvider(auth);
      let appVerifier = window.recaptchaVerifier;
      provider
        .verifyPhoneNumber(phoneNumber, appVerifier)
        .then(function (verificationId) {
          window.verificationId = verificationId;
        })
        .catch((error) => {
          // console.log(error);
          if (error.code === 'too-many-requests') {
            setMessage('Error: Too Many Requests');
            setTimeout(clearMessage, 5000);
            return;
            // setTimeout(clearMessage, 2000);
          } else if (error.code === 'auth/invalid-phone-number') {
            setMessage('Invalid number please try again');
            setTimeout(clearMessage, 5000);
            return;
          } else if (error.code === 'auth/unverified-email') {
            setMessage('Error account email is unverified');
            setTimeout(clearMessage, 5000);
            return;
          } else {
            setMessage('Error verifying please try again');
            setTimeout(clearMessage, 5000);
            return;
          }
        });
      setPhone('');
      return;
    } else {
      setMessage('Invalid number please try again');
      setTimeout(clearMessage, 5000);
      return;
    }
  };

  /*
        Verifies the message input by the user. On correct entry, phone number
        is automatically updated and a message is shown to the user.
    */
  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);

    if (otp.length === 6) {
      // console.log(otp);
      let verificationId = window.verificationId;
      let phoneCredential = PhoneAuthProvider.credential(verificationId, otp);
      updatePhoneNumber(auth.currentUser, phoneCredential)
        .then(() => {
          // userData.phone = phone;
          setUser((prevUser) => ({ ...prevUser, phone: auth.currentUser.phoneNumber }));
          // console.log('Did this get update?', user);
          setPhoneUpdate('Phone number updated');
          // setMessage(`Phone Number Has Been Updated To ${phone}`);
          setExpandForm(false);
          setMessage('');
          return;
          // setTimeout(clearMessage, 2000);
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-verification-code') {
            setMessage('Invalid verification code please try again');
            setTimeout(clearMessage, 5000);
            return;
          } else {
            setMessage('Error verifying number please try again');
            setTimeout(clearMessage, 5000);
            return;
          }
        });
    }
  };

  // Retreives all icons from firebase storage as a list with its associated url
  useEffect(() => {
    listAll(imageListRef).then((res) => {
      res.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
          // console.log(res);
        });
      });
    });
  }, []);

  //get current user from database on pageload
  useEffect(() => {
    getCurrentUser();
  }, []);

  //set user on userUpdate
  useEffect(() => {
    // console.log('user', user);
    setCurrentProfilePic(user.photoURL); //possibly in the future just remove variable currentProfilePic, everything very tangled
  }, [user]);

  // Updates the user's profile picture in firebase's authentication service
  const handleProfilePicChange = async (url) => {
    if (url === currentProfilePic) return;

    const q = query(collection(db, 'users'), where('email', '==', auth.currentUser.email)); //get user based on current auth email, could add check to see if email auth matches database auth later on
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docu) => {
      //there should only be one, but this loop works fine
      //doc.data() is never undefined for query doc snapshot -- from firestore documentation
      const userRef = doc(db, 'users', docu.id);
      let userData = docu.data();
      // console.log('userData', userData);
      let newUserData = { ...userData, photoURL: url };
      // console.log('newUserData', newUserData);

      try {
        updateDoc(userRef, newUserData);
      } catch (e) {
        // console.log(e);
        setMessage('Unable to update profile picture');
        setTimeout(clearMessage, 5000);
        return;
      }
    });

    updateProfile(auth.currentUser, { photoURL: url })
      .then(() => {
        // console.log('Profile picture updated successfully!');
        setCurrentProfilePic(url);
        return;
      })
      .catch((error) => {
        // console.log('Error updating profile picture: ', error);
        setMessage('Unable to update profile picture');
        setTimeout(clearMessage, 5000);
        return;
      });
  };
  // Permits users to save a new profile picture
  const handleSaveIcon = () => {
    // Handles a user clicking save and then setting the new profile picture
    const iconIndex = clickedIcons.indexOf(true);

    if (iconIndex === -1) {
      // This is for if a user clicks on the save button without picking an icon
      setIsClicked(false);
    } else {
      handleProfilePicChange(imageList[iconIndex]);
      setIsClicked(false);
    }
  };

  const passwordChecker = (val = '') => {
    // Enforces at least one uppercase, one lowercase, one number, one special character, no spaces, and at least 8 characters
    const validPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W])(?!.*\s).{8,}/;
    if (val.match(validPassword)) {
      // console.log('Yay it works');
      return true;
    } else {
      // console.log("Doesn't work");
      return false;
    }
  };

  const reauthenticateUser = () => {
    // Verifies user credentials before updating the password
    const user = auth.currentUser;
    const email = user.email;

    const credential = EmailAuthProvider.credential(email, currentPassword); // Attempts to get a user through the just entered password and their email

    return reauthenticateWithCredential(user, credential);
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(<Translate>Required</Translate>)
      .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
      .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
      .test({
        name: 'passwordChecker',
        message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
        test: passwordChecker,
      }),
    repeatPassword: Yup.string()
      .required(<Translate>Required</Translate>)
      .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
      .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
      .test({
        name: 'passwordChecker',
        message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
        test: passwordChecker,
      })
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleSubmit = async (values) => {
    const { newPassword, repeatPassword } = values;
    try {
      const verified = await reauthenticateUser();
      // console.log(verified);
      setCurrentPasswordError('');
      const user = auth.currentUser;
      try {
        const update = await updatePassword(user, newPassword);
        // console.log(update);
        setCurrentPasswordError('');
        signOut(auth) // Sign out the user so they can sign in with their new password
          .then(() => {
            setReset(true);
          })
          .catch(() => {
            // console.log("Couldn't log out user");
          });
        setReset(true);
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          setCurrentPasswordError('Last sign in time too long ago');
        } else {
          setCurrentPasswordError('Unable to update password please try again');
        }
      }
    } catch (error) {
      // console.log(error);
      if (error.code === 'auth/wrong-password') {
        setCurrentPasswordError('Invalid password');
      } else if (error.code === 'auth/invalid-email') {
        setCurrentPasswordError('Invalid email');
      } else if (error.code === 'auth/invalid-credential') {
        setCurrentPasswordError('Invalid credentials please try again'); // Invalid credentials possibly due to invalid token or expired credentials
      } else if (error.code === 'auth/user-not-found') {
        setCurrentPasswordError('Unable to find user'); // No user has these credentials in firebase
      } else if (error.code === 'auth/user-mismatch') {
        setCurrentPasswordError('Unable to find user with these credentials'); // Credentials don't match the user
      } else {
        setCurrentPasswordError('Unable to verify please try again');
      }
    }
  };
  // The onSubmit function that gets called to handle changing name, email, and phone number
  const handleUserInfoChange = (values) => {
    // console.log(user.name);
    // console.log(values.name);
    // console.log(values.email);
    // console.log(values.phoneNumber);
    const testNum = null;
    if (user.phoneNumber != null) {
      const testNum = user.phoneNumber.substring(2);
    }

    if (
      user.name === values.name &&
      user.email === values.email &&
      auth.currentUser.phoneNumber === values.phoneNumber
    ) {
      // console.log('no change');
      setMessage('No Changes Detected');
      setTimeout(clearMessage, 5000);
      return;
    }
    changeName(values.name);
    changeEmail(values.email);
    requestOTP(values.phoneNumber);
  };

  const userValidationSchema = Yup.object({
    name: Yup.string().max(50, 'Must be 50 characters or less'), // Feel free to change later I just couldn't get it at 15 since my name is long : (
    email: Yup.string().email('Invalid E-Mail'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Invalid Phone Number').min(10, 'Must Include Area Code'),
  });

  useEffect(() => {});

  if (reset === false) {
    return (
      <div className='profile-page-container'>
        <SignupNavbar />
        <div className='edit-profile-form-container'>
          <div className='profile-form-header'>
            <p className='profile-form-header-text'>Edit Profile</p>
          </div>
          {!isClicked ? (
            <div className='edit-profile-form-section'>
              <div className='profile-picture-section' onClick={() => setIsClicked(true)}>
                {/*In case you can't get the users avatar or picture I put the picture of the hippo as an alternative */}
                <img src={currentProfilePic || CuteHippo} alt='' className='profile-picture' />
                <img src={Camera} alt='' className='camera-icon' />
              </div>
              <Formik
                initialValues={{
                  name: user.name !== null ? user.name : '',
                  email: user.email !== null ? user.email : '', // Couldn't get the phone number to appear on screen without using auth
                  phoneNumber: auth.currentUser.phoneNumber !== null ? auth.currentUser.phoneNumber.substring(2) : '',
                }}
                enableReinitialize={true}
                onSubmit={handleUserInfoChange}
                validationSchema={userValidationSchema}
              >
                {(props) => (
                  <Form className='edit-profile-form'>
                    <div className='edit-profile-form-inputfield'>
                      <label htmlFor='profile-name' className='profile-label'>
                        Name
                      </label>
                      <input
                        type='text'
                        id='profile-name'
                        className='profile-input'
                        name='name'
                        onChange={props.handleChange}
                        value={props.values.name}
                      />
                      <div
                        className='error-message'
                        style={nameUpdate ? { color: 'rgb(13, 83, 30)' } : { color: 'rgb(182, 29, 29)' }}
                      >
                        {nameUpdate ? nameUpdate : props.errors.name}
                      </div>
                    </div>
                    <div className='edit-profile-form-inputfield'>
                      <label htmlFor='profile-email' className='profile-label'>
                        Email
                      </label>
                      <input
                        type='text'
                        id='profile-email'
                        className='profile-input'
                        onChange={props.handleChange}
                        value={props.values.email}
                        name='email'
                      />
                      <div
                        className='error-message'
                        style={emailUpdate ? { color: 'rgb(13, 83, 30)' } : { color: 'rgb(182, 29, 29)' }}
                      >
                        {emailUpdate ? emailUpdate : props.errors.email}
                      </div>
                    </div>
                    <div className='edit-profile-form-inputfield'>
                      <label htmlFor={expandForm ? 'profile-otp' : 'profile-phone'} className='profile-label'>
                        {expandForm ? 'OTP' : 'Phone'}
                      </label>
                      <input
                        type='tel'
                        id={expandForm ? 'profile-otp' : 'profile-phone'}
                        className='profile-input'
                        name={expandForm ? 'otp' : 'phoneNumber'}
                        onChange={expandForm ? verifyOTP : props.handleChange}
                        value={expandForm ? OTP : props.values.phoneNumber}
                        placeholder='123-456-7890'
                      />
                      <div
                        className='error-message'
                        style={phoneUpdate ? { color: 'rgb(13, 83, 30)' } : { color: 'rgb(182, 29, 29)' }}
                      >
                        {phoneUpdate ? phoneUpdate : props.errors.phoneNumber}
                      </div>
                    </div>
                    <div id='sign-in-button'></div>
                    <button className='edit-profile-form-button' type='submit'>
                      Save Changes
                    </button>
                    <div id='message'>{message}</div>
                    {/*console.log(
                      'name : ',
                      props.values.name,
                      ' email : ',
                      props.values.email,
                      ' phoneNumber : ',
                      props.values.phoneNumber
                    )*/}
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className='edit-profile-picture-section'>
              <div className='picture-grid'>
                {imageList.map((icon, index) => {
                  return (
                    <img
                      src={icon}
                      alt='icon'
                      className={!clickedIcons[index] ? 'profile-icons' : 'profile-icons-clicked'}
                      onClick={() => handleClickedIcon(index)}
                    />
                  );
                })}
              </div>
              <button className='picture-save-button' onClick={() => handleSaveIcon()}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className='change-password-form-container'>
          <div className='profile-form-header'>
            <p className='profile-form-header-text'>Change Password</p>
          </div>
          <div className='change-password-form-section'>
            <Formik
              initialValues={{ currentPassword: '', newPassword: '', repeatPassword: '' }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form className='edit-profile-form'>
                  <div className='edit-profile-form-inputfield'>
                    <label htmlFor='current-password' className='profile-label'>
                      Current Password
                    </label>
                    <div className='password-field-container'>
                      <input
                        type={showCurrentPassword === false ? 'password' : 'text'}
                        id='current-password'
                        className='profile-input'
                        placeholder='enter current password'
                        name='currentPassword'
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        value={currentPassword}
                        // onBlur={onPasswordBlur}
                        // onFocus={onPasswordFocus}
                      />
                      <img
                        src={showCurrentPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                        alt=''
                        className='eye-icon'
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                      />
                    </div>
                    <div
                      className='error-message'
                      style={currentPasswordError ? { visibility: 'visible' } : { visibility: 'hidden' }}
                    >
                      {currentPasswordError}
                    </div>
                  </div>
                  <div className='edit-profile-form-inputfield'>
                    <label htmlFor='change-new-password' className='profile-label'>
                      New Password
                    </label>
                    <div className='password-field-container'>
                      <input
                        type={showNewPassword === false ? 'password' : 'text'}
                        id='change-new-password'
                        className='profile-input'
                        placeholder='enter new password'
                        name='newPassword'
                        onChange={props.handleChange}
                        value={props.values.newPassword}
                        onBlur={onPasswordBlur}
                        onFocus={onPasswordFocus}
                      />
                      <img
                        src={showNewPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                        alt=''
                        className='eye-icon'
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </div>
                    <div
                      className='error-message'
                      style={props.errors.newPassword ? { visibility: 'visible' } : { visibility: 'hidden' }}
                    >
                      {props.errors.newPassword}
                    </div>
                  </div>
                  <div className='edit-profile-form-inputfield'>
                    <label htmlFor='change-repeat-password' className='profile-label'>
                      Repeat Password
                    </label>
                    <div className='password-field-container'>
                      <input
                        type={showRepeatPassword === false ? 'password' : 'text'}
                        id='change-repeat-password'
                        className='profile-input'
                        placeholder='re-enter new password'
                        name='repeatPassword'
                        onChange={props.handleChange}
                        value={props.values.repeatPassword}
                        onBlur={onPasswordBlur}
                        onFocus={onPasswordFocus}
                      />
                      <img
                        src={showRepeatPassword === false ? DONT_SHOW_PASSWORD : SHOW_PASSWORD}
                        alt=''
                        className='eye-icon'
                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                      />
                    </div>
                    <div
                      className='error-message'
                      style={props.errors.repeatPassword ? { visibility: 'visible' } : { visibility: 'hidden' }}
                    >
                      {props.errors.repeatPassword}
                    </div>
                  </div>
                  <button className='edit-profile-form-button' type='submit'>
                    Save Changes
                  </button>
                  <div className='potential-error'></div>
                </Form>
              )}
            </Formik>
            <img src={PASSWORD_PERSON} alt='' className='rafki-image' />
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <PasswordReset setReset={setReset} />;
  }
};

export default MyProfile;
