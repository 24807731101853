import React from "react";
import "./styles.scss";

const TranslateOpt = ({ language, handleChange }) => {
  return (
    <select className="select" value={language} onChange={handleChange}>
      <option value="" disabled hidden>
        Translate
      </option>
      <option value="en">English</option>
      <option value="ar">العربية</option>
      <option value="zh">中文 (简体)</option>
      <option value="fr">Français</option>
      <option value="de">Deutsch</option>
      <option value="el">Ελληνικά</option>
      <option value="ht">Kreyòl Ayisyen</option>
      <option value="id">Bahasa Indonesia</option>
      <option value="it">Italiano</option>
      <option value="ja">日本語</option>
      <option value="ko">한국어</option>
      <option value="pt">Português</option>
      <option value="ru">Русский</option>
      <option value="es">Español</option>
      <option value="ur">اردو</option>
    </select>
  );
};

export default TranslateOpt;