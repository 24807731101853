import React, { useContext } from 'react';
import { GuideContext } from '../../../../context/GuideContext';
import {
  CATEGORY,
  COLORS,
  ITEM,
  ITEMS,
  PRODUCTS_DATA,
  SUBCATEGORIES,
  SUBCATEGORY,
  TYPE_OR_COLOR,
} from 'components/Guide/ProductsData';
import { RoundCheckmarkSvgIcon } from 'components/Guide/svg/general-icons/RoundCheckmarkSvgIcon';
import './TypesOrColors.styles.scss';

const TypesOrColors = () => {
  const { formSelections, formOptions, updateFormOptionsAndFormSelections } = useContext(GuideContext);

  const updateTypeOrColorFormSelection = (field) => {
    updateFormOptionsAndFormSelections(TYPE_OR_COLOR)(field);
  };

  const handleSetButtonBackgroundColor = (itemObj, color) => {
    const patterns = {
      Pattern:
        'linear-gradient(18deg, #1277a3 25%, #59cbe8 25%, #59cbe8 50%, #1277a3 50%, #1277a3 75%, #59cbe8 75%, #59cbe8 100%)',
      MultiColor:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #819fe2, #68a3e0, #51a6da, #22a3ca, #009fb6, #00999e, #059285)',
    };

    return (
      itemObj &&
      itemObj.hasOwnProperty(COLORS) && {
        backgroundColor: !Object.keys(patterns).includes(color) && itemObj[COLORS][color],
        backgroundImage: patterns[color],
        color: [
          'Yellow',
          'Beige',
          'Tan',
          'White',
          'Clear',
          'Pink',
          'Nude',
          'Peach',
          'Rose',
          'Champagne',
          'Gold',
        ].includes(color)
          ? 'hsl(0, 0%, 20%)' // off-black
          : 'hsl(0, 0%, 98%)', // off-white
      }
    );
  };

  return (
    <>
      <div className='gntv_types-or-colors__container'>
        <div className='gntv_types-or-colors__button-container'>
          {formOptions[TYPE_OR_COLOR].map((typeOrColor) => {
            /* prettier-ignore */
            const itemObj = PRODUCTS_DATA[formSelections[CATEGORY]][SUBCATEGORIES]
            .find((subcategory) => subcategory[SUBCATEGORY] === formSelections[SUBCATEGORY])[ITEMS]
            .find((item) => item[ITEM] === formSelections[ITEM]);

            return (
              <button
                key={typeOrColor}
                type='button'
                className='gntv_types-or-colors__item-button'
                onClick={() => updateTypeOrColorFormSelection(typeOrColor)}
                data-active={formSelections[TYPE_OR_COLOR] === typeOrColor}
                data-is-color={itemObj.hasOwnProperty(COLORS)}
                style={
                  itemObj.hasOwnProperty(COLORS) ? handleSetButtonBackgroundColor(itemObj, typeOrColor) : undefined
                }
              >
                <div className='gntv_types-or-colors__select-indicator'>
                  {formSelections[TYPE_OR_COLOR] === typeOrColor && <RoundCheckmarkSvgIcon />}
                </div>
                <span className='gntv_types-or-colors__text'>{typeOrColor}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TypesOrColors;
