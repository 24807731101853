import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  ABOUT_ROUTE,
  CONTACT_ROUTE,
  PODCASTS_ROUTE,
  PRIVACY_ROUTE,
  FACEBOOK_ROUTE,
  TWITTER_ROUTE,
  INSTAGRAM_ROUTE,
} from 'pages/Routes';
import Facebook from '../../assets/icons/Social_Media/newfacebook.png';
import Twitter from '../../assets/icons/Social_Media/newtwitter.png';
import Instagram from '../../assets/icons/Social_Media/newinstagram.png';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { useLanguage } from 'components/Translate/LanguageContext';
import './styles.scss';

// Footer that's used for the sign in and sign up pages
const Footer = () => {
  const { language, changeLanguage } = useLanguage();
  // Helps to make footer more responsive when on tablet and mobile devices
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      {isWideScreen ? (
        <footer className='gn-main_footer'>
          <div className='footer_links'>
            <a href={ABOUT_ROUTE} className='footer_link'>
              <Translate>About Us</Translate>
            </a>
            <a href={CONTACT_ROUTE} className='footer_link'>
              <Translate>Contact Us</Translate>
            </a>
            <a href={PODCASTS_ROUTE} className='footer_link'>
              <Translate>Geaux Network Podcasts</Translate>
            </a>
            <a href={PRIVACY_ROUTE} className='footer_link'>
              <Translate>Privacy Policy</Translate>
            </a>
            <div className='footer_social-media'>
              <a href={FACEBOOK_ROUTE}>
                <img src={Facebook} alt='Facebook' className='footer_facebook-icon' />
              </a>
              <a href={TWITTER_ROUTE}>
                <img src={Twitter} alt='Twitter' className='footer_twitter-icon' />
              </a>
              <a href={INSTAGRAM_ROUTE}>
                <img src={Instagram} alt='Instagram' className='footer_instagram-icon' />
              </a>
            </div>
          </div>
          <p className='footer_copyright'>
            &copy; <Translate>Geaux Network</Translate>
          </p>
        </footer>
      ) : (
        <footer className='gn-main_footer'>
          <div className='footer_links'>
            <a href={ABOUT_ROUTE} className='footer_link'>
              <Translate>About Us</Translate>
            </a>
            <a href={CONTACT_ROUTE} className='footer_link'>
              <Translate>Contact Us</Translate>
            </a>
            <a href={PODCASTS_ROUTE} className='footer_link'>
              <Translate>Geaux Network Podcasts</Translate>
            </a>
            <a href={PRIVACY_ROUTE} className='footer_link'>
              <Translate>Privacy Policy</Translate>
            </a>
          </div>
          <div className='footer_social-media'>
            <a href={FACEBOOK_ROUTE}>
              <img src={Facebook} alt='Facebook' className='footer_facebook-icon' />
            </a>
            <a href={TWITTER_ROUTE}>
              <img src={Twitter} alt='Twitter' className='footer_twitter-icon' />
            </a>
            <a href={INSTAGRAM_ROUTE}>
              <img src={Instagram} alt='Instagram' className='footer_instagram-icon' />
            </a>
          </div>
          <p className='footer_copyright'>
            &copy; <Translate>Geaux Network</Translate>
          </p>
        </footer>
      )}
    </Translator>
  );
};

export default Footer;
