import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Translator, Translate } from 'react-auto-translate';

import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from 'pages/Routes';
import gx from '/src/assets/icons/Channel_Logos/gx.png';

function DelayedPopup({ isDelayedOpen, setIsDelayedOpen }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const closeModal30 = () => {
    setIsDelayedOpen(false); //experimenting with prop drilled state changing function
  };

  useEffect(() => {
    let timeoutId30;
    if (!isDelayedOpen) {
      timeoutId30 = setTimeout(() => {
        setIsDelayedOpen(true);
      }, 1000 * 60 * 30); // 30 minutes
      return () => clearTimeout(timeoutId30);
    }
  }, []);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const cursorStyle = isHovering ? { cursor: 'pointer' } : {};

  return (
    <div>
      {!user && (
        <Modal
          isOpen={isDelayedOpen}
          className='gn-tv-channel-age-modal'
          overlayClassName='gn-tv-channel-age-modal-overlay'
          ariaHideApp={false}
        >
          <div className='gn-tv-modal-container'>
            <img src={gx} alt='geaux logo' />
            <span className='close' onClick={closeModal30}>
              &times;
            </span>
            <div className='gn-tv-modal-title'>
              <span>
                <Translate>Still watching?</Translate>
              </span>

              <span>
                <Translate>Sign up to learn more!</Translate>
              </span>
            </div>
            <div className='gn-tv-channel-age-modal-button'>
              <Link to={SIGN_UP_ROUTE}>
                <button
                  className='buttonCancel'
                  type='primary'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={cursorStyle}
                >
                  <span>
                    <Translate>Sign Up</Translate>
                  </span>
                </button>
              </Link>
              <Link to={SIGN_IN_ROUTE}>
                <button
                  className='buttonEnter'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={cursorStyle}
                >
                  <span>
                    <Translate>Sign In</Translate>
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default DelayedPopup;
