import './styles.scss';
import React from 'react';
import { Oval } from 'react-loader-spinner';

const Loading = () => {
  return (
    <div className='loading'>
      <Oval
        height={200}
        width={200}
        color='#A8A7FF'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor='#A8A7FF'
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default Loading;
