import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  CREATOR_ROUTE,
  ABOUT_ROUTE,
} from 'pages/Routes';
import GEAUX_LOGO from '../../assets/icons/Sign_In_Page_Icons/geauxlogo.png';
import { Translator, Translate } from 'react-auto-translate';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useLanguage } from 'components/Translate/LanguageContext';
import { apiKey } from '../../../config.js';
import HamburgerMenu from 'components/Hamburger_Menu';
import './styles.scss';

const LANGUAGE_CLOSED = 'closed'; // Used for changing the styling in the scss
const LANGUAGE_OPENED = 'opened';

// Implements a language menu that can be used for the sign in navbar
// Users can pick a language that corresponds with a country flag
const LanguageMenu = () => {
  const { language, changeLanguage, languageFlag, languageSymbol, setLanguageSymbol, languageList, changeFlag } =
    useLanguage();
  const [languageDropdownState, setLanguageDropdownState] = useState(LANGUAGE_CLOSED);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Used to adjust the window width for responsiveness

  const toggleLanguageDropdown = () => {
    setLanguageDropdownState(languageDropdownState === LANGUAGE_CLOSED ? LANGUAGE_OPENED : LANGUAGE_CLOSED);
  };

  const updateWindowWidth = () => {
    // Determines how the language menu will render depending on the screen size
    setWindowWidth(window.innerWidth);
  };

  const handleLanguageChange = (lang, flag) => {
    changeLanguage(lang.toLowerCase());
    setLanguageSymbol(lang);
    changeFlag(flag);
  };

  // Closes menu after clicking anywhere outside of the menu
  useEffect(() => {
    let languageDropdownHandler = (e) => {
      // Refers to the user icon to open the menu
      const menu = document.querySelector('.flag-menu');

      if (menu) {
        // Prevents "Cannot read properties of null" error when the flag-menu class isn't rendered on the page
        // First condition checks that no children elements within the menu class are being targeted
        // Second condition added since it prevents the language popup menu from iniitally rendering in place then moving to the left side of the screen
        if (!menu.contains(e.target) && document.body.style.overflow !== 'hidden') {
          setLanguageDropdownState(LANGUAGE_CLOSED);
        }
      }
    };

    document.body.addEventListener('click', languageDropdownHandler);

    return () => {
      document.body.removeEventListener('click', languageDropdownHandler);
    };
  });

  useEffect(() => {
    updateWindowWidth();
    // console.log(windowWidth);

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, [windowWidth]);

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      {windowWidth > 750 ? (
        <div className='flag-menu' data-state={languageDropdownState} onClick={toggleLanguageDropdown}>
          <div className='flag-container'>
            <img src={languageFlag} alt='flag' className='flag-icon' />
            <a className='gn-main_anchor'>{languageSymbol}</a>
          </div>
          <div className='flag-dropdown-menu' data-state={languageDropdownState}>
            {languageList.map((item, index) => {
              return (
                <span
                  className='flag-container'
                  key={item.id}
                  onClick={() => handleLanguageChange(item.abbreviation, item.flag)}
                >
                  <div className='language-icon'>
                    <img src={item.flag} alt='' className='flag-icon' />
                  </div>
                  <a href='' className='flag-anchor underline-anchor'>
                    <Translate>{item.language}</Translate>
                  </a>
                </span>
              );
            })}
          </div>
        </div>
      ) : (
        <div className='flag-menu' data-state={languageDropdownState} onClick={toggleLanguageDropdown}>
          <span className='flag-holder'>
            <img src={languageFlag} alt='' className='flag' />
            <a className='lang-symbol'>{languageSymbol}</a>
          </span>
          <div className='flag-dropdown-menu' data-state={languageDropdownState}>
            {languageList.map((item, index) => {
              return (
                <span
                  className='flag-container'
                  key={item.id}
                  onClick={() => handleLanguageChange(item.abbreviation, item.flag)}
                >
                  <div className='language-icon'>
                    <img src={item.flag} alt='' className='flag-icon' />
                  </div>
                  <a href='' className='flag-anchor underline-anchor'>
                    <Translate>{item.language}</Translate>
                  </a>
                </span>
              );
            })}
          </div>
        </div>
      )}
    </Translator>
  );
};

export default LanguageMenu;
