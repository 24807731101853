import React, { useEffect, useState } from 'react';
import ChannelPopupProvider from '../../context/ChannelPopupContext';
import { connect } from 'react-redux';
import { getUserId, setUserProduct } from 'services/userProfile';
import { MainNav } from 'components/MainNav';
import Player from 'components/Player';
import Guide from 'components/Guide/Guide';
import SignUpPopup from 'components/SignUpPopup';
import ChannelAgePopup from 'components/ChannelAgePopup';
import { GuideProvider } from '../../context/GuideContext';
import LoginValidatorModal from 'components/LoginValidatorModal';
import { classMap } from 'util/classMap';
import './styles.scss';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { LanguageProvider } from 'components/Translate/LanguageContext';
import DelayedPopup from 'components/DelayedPopup';

const mapStateProps = (state) => ({
  userId: getUserId(state),
});

const mapDispatchProps = {
  setUserProduct,
};

const TV = ({ userId, setUserProduct }) => {
  const [productAdded, setProductAdded] = useState(false);
  const [isSignUpPopupOpen, setIsSignUpPopupOpen] = useState(false);
  const [isDelayedPopupOpen, setIsDelayedPopupOpen] = useState(false);

  const addProductClick = () => setProductAdded(true);

  const classes = classMap({
    'gn-tv': true,
    'gn-tv-product-selected': productAdded === true,
  });
  useEffect(()=>{
    if (isDelayedPopupOpen) setIsSignUpPopupOpen(false);
  }, [isDelayedPopupOpen])

  return (
    <>
        <LanguageProvider>
      <MainNav {...{ userId }} />
      <SignUpPopup isSignUpOpen={isSignUpPopupOpen} setIsSignUpOpen={setIsSignUpPopupOpen}/>
      <DelayedPopup isDelayedOpen={isDelayedPopupOpen} setIsDelayedOpen={setIsDelayedPopupOpen} />
      <GuideProvider>
        <ChannelPopupProvider>
          <Player {...{ addProductClick, setUserProduct, userId }} />
          <Guide />
          <ChannelAgePopup />
          <LoginValidatorModal />
        </ChannelPopupProvider>
      </GuideProvider>
      </LanguageProvider>

      {/*<Translator from='en' to={localStorage.getItem('language')} googleApiKey={apiKey}>*/}
      {/*  <div className='footer'>*/}
      {/*    <div className='footer-wrap'>*/}
      {/*      <div className='grid-1'>*/}
      {/*        <p>*/}
      {/*          <a href='https://geaux.tv/about' role='link'>*/}
      {/*            <Translate>ABOUT</Translate>*/}
      {/*          </a>*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className='grid-2'>*/}
      {/*        <p>*/}
      {/*          <a href='https://geaux.tv/issue' role='link'>*/}
      {/*            <Translate>REPORT AN ISSUE</Translate>*/}
      {/*          </a>*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className='grid-3'>*/}
      {/*        <p>*/}
      {/*          <a href='https://geaux.tv/contact' role='link'>*/}
      {/*            <Translate>CONTACT US</Translate>*/}
      {/*          </a>*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*      <div className='grid-4'>*/}
      {/*        <p>*/}
      {/*          <a href='https://geaux.tv/creators' role='link'>*/}
      {/*            <Translate>CONTENT CREATORS</Translate>*/}
      {/*          </a>*/}
      {/*        </p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Translator>*/}
    </>
  );
};

export default connect(mapStateProps, mapDispatchProps)(TV);
