import React, { useContext } from 'react';
import { GuideContext } from '../../../../context/GuideContext';
import { RoundCheckmarkSvgIcon } from 'components/Guide/svg/general-icons/RoundCheckmarkSvgIcon';
import {
  CATEGORY,
  ICON,
  ITEM,
  ITEMS,
  PRODUCTS_DATA,
  SUBCATEGORIES,
  SUBCATEGORY,
  TYPE_OR_COLOR,
} from 'components/Guide/ProductsData';
import './Subcategories.styles.scss';

const Subcategories = () => {
  const { updateFormOptionsAndFormSelections, formSelections, formOptions, setFormOptions, goToNextSection } =
    useContext(GuideContext);

  const updateSubcategoryFormSelection = (field) => {
    updateFormOptionsAndFormSelections(SUBCATEGORY, [ITEM, TYPE_OR_COLOR])(field);

    let items = [];

    if (PRODUCTS_DATA[formSelections[CATEGORY]] && PRODUCTS_DATA[formSelections[CATEGORY]][SUBCATEGORIES]) {
      let subcategory = PRODUCTS_DATA[formSelections[CATEGORY]][SUBCATEGORIES].find(
        (sub) => sub[SUBCATEGORY] === field
      );
      if (subcategory && subcategory[ITEMS]) items = subcategory[ITEMS].map((item) => item[ITEM]);
    }

    setFormOptions({ ...formOptions, [ITEMS]: items });

    if (items.length > 0) goToNextSection();
  };

  return (
    <>
      <div className='gntv_subcategories__container'>
        <div className='gntv_subcategories__button-container'>
          {formOptions[SUBCATEGORIES].map((subcategoryName) => {
            const subcategoryObj = PRODUCTS_DATA[formSelections[CATEGORY]][SUBCATEGORIES].find(
              (subcategory) => subcategory[SUBCATEGORY] === subcategoryName
            );

            return (
              <button
                key={subcategoryName}
                type='button'
                className='gntv_subcategories__item-button'
                onClick={() => updateSubcategoryFormSelection(subcategoryName)}
                data-active={formSelections[SUBCATEGORY] === subcategoryName}
              >
                <div className='gntv_subcategories__select-indicator'>
                  {formSelections[SUBCATEGORY] === subcategoryName && <RoundCheckmarkSvgIcon />}
                </div>
                {subcategoryObj[ICON]}
                <span>{subcategoryName}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Subcategories;
