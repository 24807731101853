import * as firebaseConfigDetails from "../config";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import { firebase } from 'firebase/app';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: firebaseConfigDetails.apiKey,
  authDomain: firebaseConfigDetails.authDomain,
  databaseURL: firebaseConfigDetails.databaseURL,
  projectId: firebaseConfigDetails.projectId,
  storageBucket: firebaseConfigDetails.storageBucket,
  messagingSenderId: firebaseConfigDetails.messagingSenderId,
  appId: firebaseConfigDetails.appId,
  measurementId: firebaseConfigDetails.measurementId,
};


const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);