import React, { useContext, useState } from 'react';
import './styles.scss';
import gx from '/src/assets/icons/Channel_Logos/gx.png';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';
import { GuideContext } from '../../context/GuideContext';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from 'pages/Routes';

const LoginValidatorModal = () => {
  const { isSignUpValidatorModalOpen, closeSignUpValidatorModal } = useContext(GuideContext);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const closeModal15 = () => {
    closeSignUpValidatorModal();
  };
  const cursorStyle = isHovering ? { cursor: 'pointer' } : {};

  if (!isSignUpValidatorModalOpen) return null;

  return (
    <Translator from='en' to={localStorage.getItem('language')} googleApiKey={apiKey}>
      <div className='gn-tv-channel-age-modal-overlay'>
        <div className='gn-tv-channel-age-modal'>
          <div className='gn-tv-modal-container'>
            {/* <button className='close-button' onClick={closeSignUpValidatorModal}>
              X
            </button> */}
            <span className='close' onClick={closeModal15}>
              &times;
            </span>
            <img src={gx} alt='Logo' />
            <div className='gn-tv-modal-title'>
              <span>
                <Translate>Hey, good to see you here!</Translate>
              </span>

              <span>
                <Translate>Enjoying what you see? Come on in and join the experience!</Translate>
              </span>
            </div>
            <div className='gn-tv-channel-age-modal-button'>
              <button className='buttonCancel' onClick={closeSignUpValidatorModal}>
                <span>
                  <Translate>Still Browsing</Translate>
                </span>
              </button>
              {/* <a id='sign-out' className='buttonEnter' href='https://player.geaux.tv/signin' onClick={() => {}}>
                
                  <Translate>Join in</Translate>
                
              </a> */}
              <Link to={SIGN_IN_ROUTE}>
                <button
                  className='buttonEnter'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={cursorStyle}
                >
                  <span>
                    <Translate>Sign In</Translate>
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Translator>
  );
};

export default LoginValidatorModal;
