import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Components
import Loading from 'components/Loading';
//Pages
import CreateAccount from 'pages/CreateAccount';
import SignIn from 'pages/SignIn';
import TV from 'pages/TV';
import UserProfile from 'pages/UserProfile';
import { NotFound } from 'pages/NotFound';
// Misc
import { data } from 'database';
import { parseQueryString } from 'util/parseQueryString';
import { setVideoInfo } from 'services/channelInfo';
import { UserProvider, useUser } from 'hooks/use-user';
import ForgotPassword from 'pages/ForgotPassword';
import { LanguageProvider } from 'components/Translate/LanguageContext';
import ResetPassword from 'pages/ResetPassword';
import MyProfile from 'pages/MyProfile';

export const HOME_ROUTE = 'https://geaux.tv/';
export const SUPPORT_ROUTE = 'https://geaux.tv/support';
export const CREATOR_ROUTE = 'https://geaux.tv/creators/';
export const ABOUT_ROUTE = 'https://geaux.tv/about/';
export const PRIVACY_ROUTE = 'https://geaux.tv/privacypolicy/';
export const CONTACT_ROUTE = 'https://geaux.tv/contact/';
export const PODCASTS_ROUTE = 'https://geauxnet.com/audio';
export const FACEBOOK_ROUTE = 'https://www.facebook.com/geauxnetwork';
export const INSTAGRAM_ROUTE = 'https://www.instagram.com/geauxnetwork/';
export const TWITTER_ROUTE = 'https://twitter.com/geauxnetwork';
export const PLAYER_ROUTE = '/';
export const SIGN_IN_ROUTE = '/signin';
export const SIGN_UP_ROUTE = '/signup';
export const PROFILE_ROUTE = '/oldprofile'; // MY_PROFILE is the new route now although keeping this at the moment in case need to use some of the old profile code
export const CHANNEL_ROUTE = 'https://player.geaux.tv/';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const MY_PROFILE = '/profile';
const INVALID_ROUTE = '*'; //this route doesn't need to be exported

function ProviderWrappedApp() {
  return (
    <BrowserRouter>
      <UserProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

function App() {
  // Hooks - dispatch
  const dispatch = useDispatch();

  // Hooks - state
  const [channelQuery, setshannelQuery] = useState(null);

  const userState = useUser();
  const { channel } = parseQueryString();
  const channelCategories = ['Featured', 'Kids', 'Business/News', 'Discover', 'Music', 'Science/Tech'];

  // Hooks - effects
  useEffect(() => {
    // If there is a channel param update redux with correct channel info
    if (channel) {
      const channelArray = channelCategories.map((category) => {
        const findChannelInfo = data[category].find((chObject) => chObject.number === parseInt(channel));
        return findChannelInfo;
      });

      function removeItemAll(arr, value) {
        var i = 0;
        while (i < arr.length) {
          if (arr[i] === value) {
            arr.splice(i, 1);
          } else {
            ++i;
          }
        }
        return arr;
      }

      const remainingChannel = removeItemAll(channelArray, undefined);

      setshannelQuery(remainingChannel[0]);
    }
  }, []);

  if (channelQuery) {
    dispatch(
      setVideoInfo({
        channelName: channelQuery.channelName,
        channelNumber: channelQuery.number,
        channelDescription: channelQuery.channelDescription || '',
        channelIcon: channelQuery.channelIcon || '',
        channelLink: channelQuery.channelLink || '',
        videoId: channelQuery.episodes[0].videoId,
      })
    );
  }

  return (
    <>
      {userState.isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        /**
         * All routes should be made into constants if any new routes are made
         * The preferred paths of routes (at least requested by Dmitriy as of July 2023) are as follows:
         * Player itself: Root (/)
         * Sign In Page: /signin
         * Sign Up Page: /signup
         * My Profile: /profile
         *
         * Requested that each channel have their own urls, preferably based off of the channel number
         * Example: https://player.geaux.tv/player?channel=101 , https://player.geaux.tv/player?channel=17
         * Might need to be slightly different than this example, ask Dmitriy for clarification.
         * With the root Route, may be complicated, because which channel plays on just the root?
         *
         * Hailey F July 2023
         */
        <Switch>
          <Route exact path={SIGN_IN_ROUTE}>
            <SignIn />
          </Route>
          <Route exact path={SIGN_UP_ROUTE}>
            <CreateAccount />
          </Route>
          <Route exact path={PROFILE_ROUTE}>
            <UserProfile />
          </Route>
          <Route exact path={MY_PROFILE}>
            <MyProfile />
          </Route>
          {/* The current channel route listed here would be for urls like https://player.geaux.tv/player/101 rather than https://player.geaux.tv/player?channel=17*/}
          {/*Will likely need changed later*/}
          <Route path={CHANNEL_ROUTE}>
            <TV />
          </Route>
          <Route exact path={FORGOT_PASSWORD_ROUTE}>
            <ForgotPassword />
          </Route>
          <Route exact path={RESET_PASSWORD_ROUTE}>
            <ResetPassword />
          </Route>
          <Route exact path={PLAYER_ROUTE}>
            <TV />
          </Route>
          {/**Defaults to this page if a route doesn't exist or is invalid */}
          <Route path={INVALID_ROUTE}>
            <NotFound />
          </Route>
        </Switch>
      )}
    </>
  );
}

export default ProviderWrappedApp;
