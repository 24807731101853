import React, { useContext } from 'react';
import { GuideContext } from '../../../../../context/GuideContext';
import './BackButton.styles.scss';
import { ChevronLeftSvgIcon } from 'components/Guide/svg/general-icons/ChevronLeftSvgIcon';

export const BackButton = () => {
  const { goToPreviousSection, isFirstStep } = useContext(GuideContext);

  return (
    <>
      <button className='gntv_back-button__button' onClick={goToPreviousSection} data-is-first-step={isFirstStep}>
        <ChevronLeftSvgIcon />
        <span>Back</span>
      </button>
    </>
  );
};
