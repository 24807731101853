import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  CREATOR_ROUTE,
  ABOUT_ROUTE,
} from 'pages/Routes';
import GEAUX_LOGO from '../../assets/icons/Sign_In_Page_Icons/geauxlogo.png';
import { Translator, Translate } from 'react-auto-translate';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useLanguage } from 'components/Translate/LanguageContext';
import ENGLISH from '../../assets/icons/Language_Icons/USA.png';
import SPANISH from '../../assets/icons/Language_Icons/Spain.png';
import KOREAN from '../../assets/icons/Language_Icons/South_Korea.png';
import ARABIC from '../../assets/icons/Language_Icons/Saudi_Arabia.png';
import RUSSIAN from '../../assets/icons/Language_Icons/Russia.png';
import PORTUGUESE from '../../assets/icons/Language_Icons/Portugal.png';
import URDU from '../../assets/icons/Language_Icons/Pakistan.png';
import JAPANESE from '../../assets/icons/Language_Icons/Japan.png';
import ITALIAN from '../../assets/icons/Language_Icons/Italy.png';
import INDONESIAN from '../../assets/icons/Language_Icons/Indonesia.png';
import HAITIAN_CREOLE from '../../assets/icons/Language_Icons/Haiti.png';
import GREEK from '../../assets/icons/Language_Icons/Greece.png';
import GERMAN from '../../assets/icons/Language_Icons/Germany.png';
import FRENCH from '../../assets/icons/Language_Icons/France.png';
import CHINESE from '../../assets/icons/Language_Icons/China.png';
import { apiKey } from '../../../config.js';
import HamburgerMenu from 'components/Hamburger_Menu';
import LanguageMenu from 'components/LanguageMenu';
import './styles.scss';

const LANGUAGE_CLOSED = 'closed';
const LANGUAGE_OPENED = 'opened';

// Navbar that's used when on sign up, forgot password, and reset password screens. Contains a language menu.
const SignupNavbar = () => {
  const { language, changeLanguage } = useLanguage();
  const isSignIn = false; // Passed to HamburgerMenu component to determine what menu options to render

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Used to adjust the window width for responsiveness

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowWidth();
    // console.log(windowWidth);

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, [windowWidth]);

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='gn-main_navi'>
        <div>
          <a href={HOME_ROUTE}>
            <img className='gn-main_navi-logo' src={GEAUX_LOGO} alt='Geaux Network Logo' />
          </a>
        </div>
        {windowWidth > 750 ? (
          <div className='gn-main_anchors'>
            <a href={HOME_ROUTE} className='gn-main_anchor'>
              <Translate>Home</Translate>
            </a>
            <a href={PLAYER_ROUTE} className='gn-main_anchor'>
              <Translate>Channels</Translate>
            </a>
            <a href={CREATOR_ROUTE} className='gn-main_anchor'>
              <Translate>For Creators</Translate>
            </a>
            <a href={ABOUT_ROUTE} className='gn-main_anchor'>
              <Translate>About Us</Translate>
            </a>
            <a href={SIGN_IN_ROUTE} className='gn-main_anchor bold-anchor'>
              <Translate>Sign In</Translate>
            </a>
            <LanguageMenu />
          </div>
        ) : (
          <HamburgerMenu isSignIn={isSignIn} />
        )}
      </div>
    </Translator>
  );
};

export default SignupNavbar;
