import './styles.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Formik, Form } from 'formik';
import { FormikField } from '../../components/FormikField';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { setUser } from '../../services/userProfile';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import gxLogo from '../../assets/icons/Channel_Logos/gx.png';
import { Translator, Translate } from 'react-auto-translate';
import TranslateOpt from '../../components/Translate/index.js';
import { apiKey } from '../../../config.js';
import { SIGN_IN_ROUTE, RESET_PASSWORD_ROUTE } from 'pages/Routes';
import SignupNavbar from 'components/SignupNavbar';
import { useLanguage } from 'components/Translate/LanguageContext';
import Footer from 'components/Footer';
import EMAIL_ICON from '../../assets/icons/Forgot_Password/email-icon.png';
import EmailSent from 'components/SuccessScreens/EmailSent';

const mapDispatchProps = {
  setUser,
};

const initialResetValues = {
  email: '',
};

const ResetPasswordInitialSchema = Yup.object().shape({
  email: Yup.string().required(<Translate>Required</Translate>),
});

// Used to send a password reset request to the user's email and then follow the provided link.
const ForgotPassword = (props) => {
  //States
  const [error, setError] = useState('');
  const [passwordResetted, setPasswordResetted] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const auth = getAuth();

  const { language, changeLanguage } = useLanguage();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(<Translate>Invalid email address</Translate>),
    }),
    onSubmit: async (values) => {
      const formatEmail = values.email.toLowerCase();

      try {
        await sendPasswordResetEmail(auth, formatEmail); // Don't need the third argument
        setError('');
        setPasswordResetted(true);
      } catch (error) {
        if (error.code === 'auth/invalid-email') {
          // console.log('Invalid email please try again');
          setError('Invalid email please try again');
        } else if (error.code === 'auth/missing-email') {
          // console.log('Please enter a valid email address');
          setError('Please enter a valid email address');
        } else if (error.code === 'auth/user-not-found') {
          // console.log('Please enter a valid email address');
          setError('Entered email is not associated with a user');
        } else {
          // console.log('Unable to send an email reset link');
          setError('Unable to send an email reset link please try again');
          // console.log(error);
        }
      }
    },
  });

  // const redirectToSignIn = () => {
  //   window.location.href = SIGN_IN_ROUTE;
  // };

  if (!passwordResetted) {
    return (
      <Translator from='en' to={language} googleApiKey={apiKey}>
        <div className='signin-page-container'>
          <SignupNavbar />
          <div className='signin-boxi'>
            <h1 className='signin-headeri'>
              <Translate>Forgot Password?</Translate>
            </h1>
            <p className='signin-subheaderi'>
              <Translate>Enter your email below to get reset link</Translate>
            </p>
            <Formik initialValues={initialResetValues} onSubmit={formik.handleSubmit}>
              {(props) => {
                return (
                  <Form className='form-container'>
                    <FormikField
                      label={<Translate>Email</Translate>}
                      name='email'
                      type='email'
                      {...formik.getFieldProps('email')}
                    />
                    {/*formik.touched.email && formik.errors.email ? <div clas>{formik.errors.email}</div> : null*/}
                    {/** This is just to prevent merge conflict */}
                    <button className='gn-signup-buttoni' type='submit'>
                      <Translate>Send Link</Translate>
                    </button>
                    <div className='gn-sign_up-error'>{error}</div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <Footer />
        </div>
      </Translator>
    );
  } else {
    return <EmailSent />;
  }
};

export default connect(null, mapDispatchProps)(ForgotPassword);
