/* eslint-disable no-template-curly-in-string */
import { string, ref } from 'yup';
import { Translator, Translate } from 'react-auto-translate';
import React from 'react';

const LOWER = /[a-z]/;
const UPPER = /[A-Z]/;
const NUMBER = /\d/;
const SYMBOL = /[^a-z0-9]/i;

function validCharacters(val = '') {
  // Minimum_length = "8"
  if (val.length < 8) {
    return false;
  }
  // Require_lowercase = "true"
  if (LOWER.test(val) === false) {
    return false;
  }
  // Require_numbers = "true"
  if (NUMBER.test(val) === false) {
    return false;
  }
  // Require_symbols = "true"
  if (SYMBOL.test(val) === false) {
    return false;
  }
  // Require_uppercase = "true"
  if (UPPER.test(val) === false) {
    return false;
  }
  // Checks passed
  return true;
}

const passwordSchema = string()
  .required(<Translate>Required</Translate>)
  .min(8, <Translate>Must be at least 8 characters</Translate>) // Had to hardcode the 8 in order for the Translate comoponent to be used
  .max(100, <Translate>Password cannot be more than 100 characters</Translate>)
  .test({
    name: 'validCharacters',
    message: <Translate>Numbers, symbols, uppercase and lowercase characters required</Translate>,
    test: validCharacters,
  })
  .when('emailAddress', {
    is: (val) => val,
    then: string().notOneOf([ref('emailAddress')], <Translate>Password cannot be the same as email</Translate>),
    otherwise: string(),
  });

export { passwordSchema };
