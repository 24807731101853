import React, { useContext } from 'react';
import { GuideContext } from '../../../../../context/GuideContext';
import {
  CATEGORY,
  ITEM,
  ITEMS,
  SUBCATEGORY,
  SUCCESS,
  TYPE_OR_COLOR,
  TYPES_OR_COLORS,
} from 'components/Guide/ProductsData';
import { getAuth } from 'firebase/auth';
import { postForm } from '../../../../../../airtable-request';
import { getChannelInfo } from 'services/channelInfo';
import { getUserId } from 'services/userProfile';
import { connect } from 'react-redux';
import './SubmitButton.styles.scss';
import Error from '../../../error/Error';
//import html2canvas from 'html2canvas';
import { storage } from '../../../../../firebase-config';
import { listAll, ref, getDownloadURL, uploadBytes, uploadString } from 'firebase/storage';
import { event } from 'react-ga';

const mapStateProps = (state) => ({
  channelInfo: getChannelInfo(state),
  userId: getUserId(state),
});

const SubmitButtonComponent = ({ channelInfo }) => {
  const { shouldShowError, setShouldShowError, formSelections, formOptions, setIsFormSubmitted, setCurrentSection } =
    useContext(GuideContext);

  // const handleScreenshot = async () => {
  //   const canvas = await html2canvas(document.querySelector('.gntv_form-modal__container')); // Sets up what HTML content will be in the image
  //   // console.log(canvas);
  //   const data = canvas.toDataURL('image/png'); // Creates image url
  //   // console.log(data);
  //   const fileName = 'screenshot.png';
  //   const screenshotRef = ref(storage, `GGI Snapshots/${fileName}`);
  //   // console.log('screenshotRef: ', screenshotRef);

  //   await uploadString(screenshotRef, data, 'data_url'); // Uploads image to the storage ref
  //   const screenshotURL = await getDownloadURL(screenshotRef); // Retrieves image url
  //   // .then((url) => {
  //   //   const xhr = new XMLHttpRequest();
  //   //   xhr.responseType = 'blob';
  //   //   xhr.onload = (event) => {
  //   //     const blob = xhr.response;
  //   //   };
  //   //   xhr.open('GET', url);
  //   //   xhr.send();
  //   // })
  //   // .catch((error) => {
  //   //   console.log(error);
  //   // });

  //   // console.log('screenshotURL: ', screenshotURL);
  //   return screenshotURL;
  // };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setShouldShowError(true);

    /*
     * IMPORTANT: keep this code commented out.
     * It's used for forcing user to select all options before submitting the form.
     * It's commented out because it's not a requirement anymore, but it might be in the future.
     */
    // if (formSelections[CATEGORY] === '' || formSelections[SUBCATEGORY] === '') return;
    // if (formSelections[ITEM] === '' && formOptions[ITEMS]?.length > 0) return;
    // if (formSelections[TYPE_OR_COLOR] === '' && formOptions[TYPES_OR_COLORS]?.length > 0) return;

    setIsFormSubmitted(true);
    // const screenshotURL = await handleScreenshot();
    // console.log('screenshot: ', screenshotURL);

    // const screenshot = [{ url: screenshotURL }]; // Gets the cloud storage url for the image

    const {
      [CATEGORY]: itemCategory,
      [SUBCATEGORY]: itemSubcategory,
      [ITEM]: itemType,
      [TYPE_OR_COLOR]: typeOrColor,
    } = formSelections;

    const {
      currentUser: { email: userEmail, phoneNumber: userPhoneNumber },
    } = getAuth();

    postForm(
      userEmail === null || userEmail === undefined ? userPhoneNumber : userEmail,
      channelInfo.channelNumber,
      itemCategory,
      itemSubcategory,
      itemType,
      typeOrColor,
      // screenshot
    );

    setCurrentSection(SUCCESS);
  };

  return (
    <>
      {/*{shouldShowError && <Error />}*/}
      <button className='gntv_submit-button__button' type='submit' onClick={handleFormSubmit}>
        Submit
      </button>
    </>
  );
};

const SubmitButton = connect(mapStateProps)(SubmitButtonComponent);

export { SubmitButton };
