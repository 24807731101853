import React from "react";
import { Translator, Translate } from 'react-auto-translate';

class NotFound extends React.Component {
  render() {
    return (
      <div className="NotFound">
        <h1> <Translate>Page Not Found!</Translate> </h1>
      </div>
    );
  }
}

export { NotFound };
