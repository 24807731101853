import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Translator, Translate } from 'react-auto-translate';
import gx from '/src/assets/icons/Channel_Logos/gx.png';

import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from 'pages/Routes';

function SignUpPopup({ isSignUpOpen, setIsSignUpOpen }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [showPopup15, setShowPopup15] = useState(false);
  const closeModal15 = () => {
    setIsSignUpOpen(false);
  };

  useEffect(() => {
    let timeoutId15;
    if (!isSignUpOpen) {
      timeoutId15 = setTimeout(() => {
        setIsSignUpOpen(true);
      }, 900000); // 15 minutes 
      return () => clearTimeout(timeoutId15);
    }
  }, []);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const cursorStyle = isHovering ? { cursor: 'pointer' } : {};

  // If user is not signed in (user returns true), show the popup
  return (
    <div>
      {!user && (
        <div>
          <Modal
            isOpen={isSignUpOpen}
            className='gn-tv-channel-age-modal'
            overlayClassName='gn-tv-channel-age-modal-overlay'
            ariaHideApp={false}
          >
            <div className='gn-tv-modal-container'>
              <img src={gx} alt='geaux logo' />
              <span className='close' onClick={closeModal15}>
                &times;
              </span>
              <div className='gn-tv-modal-title'>
                <span>
                  <Translate>Are you enjoying these channels?</Translate>
                </span>

                <span>
                  <Translate>Sign up to watch more!</Translate>
                </span>
              </div>
              <div className='gn-tv-channel-age-modal-button'>
                <Link to={SIGN_UP_ROUTE}>
                  <button
                    className='buttonCancel'
                    type='primary'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={cursorStyle}
                  >
                    <span>
                      <Translate>Sign Up</Translate>
                    </span>
                  </button>
                </Link>
                <Link to={SIGN_IN_ROUTE}>
                  <button
                    className='buttonEnter'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={cursorStyle}
                  >
                    <span>
                      <Translate>Sign In</Translate>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default SignUpPopup;
