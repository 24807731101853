import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import {
  PLAYER_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  RESET_PASSWORD_ROUTE,
  HOME_ROUTE,
  CHANNEL_ROUTE,
  CREATOR_ROUTE,
  ABOUT_ROUTE,
} from 'pages/Routes';
import GEAUX_LOGO from '../../assets/icons/Sign_In_Page_Icons/geauxlogo.png';
import HAMBURGER_ICON from '../../assets/icons/Sign_In_Page_Icons/hamburger-menu.png';
import { Translator, Translate } from 'react-auto-translate';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useLanguage } from 'components/Translate/LanguageContext';
import ENGLISH from '../../assets/icons/Language_Icons/USA.png';
import SPANISH from '../../assets/icons/Language_Icons/Spain.png';
import KOREAN from '../../assets/icons/Language_Icons/South_Korea.png';
import ARABIC from '../../assets/icons/Language_Icons/Saudi_Arabia.png';
import RUSSIAN from '../../assets/icons/Language_Icons/Russia.png';
import PORTUGUESE from '../../assets/icons/Language_Icons/Portugal.png';
import URDU from '../../assets/icons/Language_Icons/Pakistan.png';
import JAPANESE from '../../assets/icons/Language_Icons/Japan.png';
import ITALIAN from '../../assets/icons/Language_Icons/Italy.png';
import INDONESIAN from '../../assets/icons/Language_Icons/Indonesia.png';
import HAITIAN_CREOLE from '../../assets/icons/Language_Icons/Haiti.png';
import GREEK from '../../assets/icons/Language_Icons/Greece.png';
import GERMAN from '../../assets/icons/Language_Icons/Germany.png';
import FRENCH from '../../assets/icons/Language_Icons/France.png';
import CHINESE from '../../assets/icons/Language_Icons/China.png';
import Avatar from '../../assets/icons/Sign_In_Page_Icons/user.png';
import Person from '../../assets/icons/Sign_In_Page_Icons/personicon.png';
import BELL_ICON from '../../assets/icons/Sign_In_Page_Icons/whitebellicon.png';
import CREATOR_ICON from '../../assets/icons/Sign_In_Page_Icons/voice-mail--mic-audio-mike-music-microphone.png';
import HOME_ICON from '../../assets/icons/Sign_In_Page_Icons/home-4--home-house-roof-shelter.png';
import ABOUT_ICON from '../../assets/icons/Sign_In_Page_Icons/information-circle--information-frame-info-more-help-point-circle.png';
import CHANNEL_ICON from '../../assets/icons/Sign_In_Page_Icons/play-list-4--screen-television-display-player-movies-players-tv-media-video-entertainment.png';
import LANGUAGE_ICON from '../../assets/icons/Sign_In_Page_Icons/dictionary-language-book.png';
import SIGNIN_ICON from '../../assets/icons/Sign_In_Page_Icons/login-1--arrow-enter-frame-left-login-point-rectangle.png';
import { apiKey } from '../../../config.js';
import SettingsMenu from 'components/Settings_Menu';
import LanguageMenu from 'components/LanguageMenu';
import './styles.scss';

const HAMBURGER_CLOSED = 'closed'; // Is used within the scss file to set the styling for when the hamburger menu is opened or closed
const HAMBURGER_OPENED = 'opened';

// Menu that's used to display navbar, language, and profile options when screen size gets smaller
const HamburgerMenu = (props) => {
  const { language, changeLanguage } = useLanguage();

  const [hamburgerDropdown, setHamburgerDropdown] = useState(HAMBURGER_CLOSED); // Sets the state for whether the menu is opened or closed

  const toggleHamburgerDropdown = () =>
    setHamburgerDropdown(hamburgerDropdown === HAMBURGER_CLOSED ? HAMBURGER_OPENED : HAMBURGER_CLOSED); // Closes or opens the menu

  useEffect(() => {
    let hamburgerDropdownHandler = (e) => {
      // Refers to the user icon to open the menu
      const menu = document.querySelector('.gn-main_hamburger-menu');

      if (menu) {
        // Prevents "Cannot read properties of null" error when the gn-main_hamburger-menu class isn't rendered on the page
        // First condition checks that no children elements within the menu class are being targeted
        // Second condition added since it prevents the language popup menu from iniitally rendering in place then moving to the left side of the screen
        if (!menu.contains(e.target) && document.body.style.overflow !== 'hidden') {
          setHamburgerDropdown(HAMBURGER_CLOSED);
        }
      }
    };

    document.body.addEventListener('click', hamburgerDropdownHandler);

    return () => {
      document.body.removeEventListener('click', hamburgerDropdownHandler);
    };
  });

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='gn-main_hamburger-menu' data-state={hamburgerDropdown}>
        <img className='hamburger-icon' src={HAMBURGER_ICON} onClick={toggleHamburgerDropdown} />
        <div className='hamburger-menu-dropdown' data-state={hamburgerDropdown}>
          <span className='anchor-container'>
            <img className='dropdown-bell' src={HOME_ICON} alt='Geaux Network Logo' />{' '}
            <a href={HOME_ROUTE} className='dropdown-anchor underline-anchor'>
              <Translate>Home</Translate>
            </a>
          </span>
          <div className='dropdown-line'></div>
          <span className='anchor-container'>
            <img className='dropdown-bell' src={CHANNEL_ICON} alt='Geaux Network Logo' />{' '}
            <a href={PLAYER_ROUTE} className='dropdown-anchor underline-anchor'>
              <Translate>Channels</Translate>
            </a>
          </span>
          <div className='dropdown-line'></div>
          <span className='anchor-container'>
            <img className='dropdown-bell' src={CREATOR_ICON} alt='Geaux Network Logo' />{' '}
            <a href={CREATOR_ROUTE} className='dropdown-anchor underline-anchor'>
              <Translate>For Creators</Translate>
            </a>
          </span>
          <div className='dropdown-line'></div>
          <span className='anchor-container'>
            <img className='dropdown-bell' src={ABOUT_ICON} alt='Geaux Network Logo' />{' '}
            <a href={ABOUT_ROUTE} className='dropdown-anchor underline-anchor'>
              <Translate>About Us</Translate>
            </a>
          </span>
          {props.isSignIn ? (
            <>
              <div className='dropdown-line'></div>
              <span className='anchor-container'>
                <img className='dropdown-bell' src={BELL_ICON} alt='Geaux Network Logo' />{' '}
                <a className='dropdown-anchor'>
                  <Translate>Notifications</Translate>
                </a>
              </span>
              <div className='dropdown-line'></div>
              <span className='anchor-container'>
                <SettingsMenu />
                <a className='dropdown-anchor'>
                  <Translate>Profile</Translate>
                </a>
              </span>
            </>
          ) : (
            <>
              <div className='dropdown-line'></div>
              <span className='anchor-container'>
                <img className='dropdown-bell' src={SIGNIN_ICON} alt='Geaux Network Logo' />{' '}
                <a className='dropdown-anchor' href={SIGN_IN_ROUTE}>
                  <Translate>Sign In</Translate>
                </a>
              </span>
              <div className='dropdown-line'></div>
              <span className='anchor-container'>
                <img className='dropdown-bell' src={LANGUAGE_ICON} alt='Geaux Network Logo' />
                <a className='dropdown-anchor'>
                  <LanguageMenu />
                </a>
              </span>
            </>
          )}
        </div>
      </div>
    </Translator>
  );
};

export default HamburgerMenu;
