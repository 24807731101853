import React, { useContext } from 'react';
import { CATEGORY, SUBCATEGORY, ITEM, TYPE_OR_COLOR } from 'components/Guide/ProductsData';
import { GuideContext } from '../../../../context/GuideContext';
import { FormSvgIcon } from 'components/Guide/svg/general-icons/FormSvgIcon';
import './Success.styles.scss';

const Success = () => {
  const { formSelections } = useContext(GuideContext);

  return (
    <>
      <div className='gntv_success__container'>
        <FormSvgIcon />

        <div className='gntv_success__success-text'>
          <span>Thanks for letting us know.<br></br> We will Geaux Get This for you!</span>
        </div>
        <hr className='styled-line' />
      </div>

      <div className='gntv_success__form-selection'>
        {formSelections[CATEGORY] && (
          <div>
            <span>What kind of item is it?*</span>
            <span>{formSelections[CATEGORY]}</span>
          </div>
        )}
        {formSelections[SUBCATEGORY] && (
          <div>
            <span>What's the item you're interested in?*</span>
            <span>{formSelections[SUBCATEGORY]}</span>
          </div>
        )}
        {formSelections[ITEM] && (
          <div>
            <span>Can you specify what the item is?*</span>
            <span>{formSelections[ITEM]}</span>
          </div>
        )}
        {formSelections[TYPE_OR_COLOR] && (
          <div>
            <span>Select the type/color of the item:*</span>
            <span>{formSelections[TYPE_OR_COLOR]}</span>
          </div>
        )}
      </div>
    </>
  );
};

export { Success };
