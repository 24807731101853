import React, { createContext, useEffect, useState } from 'react';
import {
  CATEGORIES,
  CATEGORY,
  ITEM,
  ITEMS,
  PRODUCTS_DATA,
  SECTIONS_ARRAY,
  SUBCATEGORIES,
  SUBCATEGORY,
  SUCCESS,
  TYPE_OR_COLOR,
  TYPES_OR_COLORS,
} from 'components/Guide/ProductsData';
import { getAuth } from 'firebase/auth';

export const GuideContext = createContext();

const GuideProvider = ({ children }) => {
  const [formOptions, setFormOptions] = useState({
    [CATEGORIES]: Object.keys(PRODUCTS_DATA),
    [SUBCATEGORIES]: [],
    [ITEMS]: [],
    [TYPES_OR_COLORS]: [],
  });

  const [formSelections, setFormSelections] = useState({
    [CATEGORY]: '',
    [SUBCATEGORY]: '',
    [ITEM]: '',
    [TYPE_OR_COLOR]: '',
  });

  const [currentSection, setCurrentSection] = useState(CATEGORIES);
  const [lastSection, setLastSection] = useState();

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSignUpValidatorModalOpen, setIsSignUpValidatorOpen] = useState(false);

  const [shouldShowError, setShouldShowError] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * This function opens form modal.
   * If there is a current user, the form modal is opened.
   * If there is no current user, the sign-up validator modal is opened instead.
   * Also, prevents scrolling in the Y-direction on the body while the modal is open.
   */
  const openFormModal = () => {
    document.body.style.overflowY = 'hidden';
    let { currentUser }  = getAuth();
    currentUser ? setIsFormModalOpen(true) : setIsSignUpValidatorOpen(true);
  };

  /**
   * This function closes the form modal and resets the form after a delay(300ms).
   * It also allows scrolling in the Y-direction on the body and hides any error messages.
   * After a delay of 300ms, it resets the form submission status and resets the form.
   */
  const closeFormModal = () => {
    document.body.style.overflowY = 'auto';
    setIsFormModalOpen(false);
    setShouldShowError(false);
    setTimeout(() => {
      setIsFormSubmitted(false);
      resetForm();
    }, 300);
  };

  /** Closes the sign-up validator modal. */
  const closeSignUpValidatorModal = () => {
    setIsSignUpValidatorOpen(false);
  };

  /**
   * This function goes to the next section in the form process if the current section is
   * either CATEGORIES, SUBCATEGORIES or ITEMS.
   *
   * It changes the 'currentSection' state to the next one in the sequence as defined in 'SECTIONS_ARRAY'.
   */
  const goToNextSection = () => {
    if ([CATEGORIES, SUBCATEGORIES, ITEMS].includes(currentSection)) {
      setCurrentSection(SECTIONS_ARRAY[SECTIONS_ARRAY.indexOf(currentSection) + 1]);
    }
  };

  /**
   * This function goes to the previous section in the form process if the current section is
   * either SUBCATEGORIES, ITEMS, or TYPES_OR_COLORS.
   *
   * It changes the 'currentSection' state to the previous one in the sequence as defined in 'SECTIONS_ARRAY'.
   */
  const goToPreviousSection = () => {
    if ([SUBCATEGORIES, ITEMS, TYPES_OR_COLORS].includes(currentSection)) {
      setCurrentSection(SECTIONS_ARRAY[SECTIONS_ARRAY.indexOf(currentSection) - 1]);
    }
  };

  /**
   * This function updates form options and form selections based on the provided field and value.
   *
   * @param {string} field - The field in the form selection to update.
   * @param {Array} [resetFields=[]] - The fields to reset in the form selection.
   * @return {function} A function that accepts a value to set for the given field in the form selection.
   * This returned function will also update the form options
   * and the last section based on the provided field and value.
   *
   * The provided value will be set in the form selection for the given field,
   * and all fields in `resetFields` will be reset.
   * In addition, the form options for all fields that have the same name as the provided field
   * or contain it in their names will be reset.
   * Finally, the last section will be set to the corresponding section for the provided field,
   * and any existing form error will be cleared.
   */
  const updateFormOptionsAndFormSelections =
    (field, resetFields = []) =>
    (value) => {
      // reset form options for fields that contain the provided field in their names
      const formOptionsFieldsToReset = [CATEGORY, SUBCATEGORY, ITEM].filter(
        (val) => val === field || val.indexOf(field) !== -1
      );

      // update form options
      setFormOptions((prevOptions) => ({
        ...prevOptions,
        ...formOptionsFieldsToReset.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
      }));

      // update form selections
      setFormSelections((prevSelection) => ({
        ...prevSelection,
        [field]: value,
        ...resetFields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: prevSelection[field] === value ? '' : '',
          }),
          {}
        ),
      }));

      const sectionMap = {
        [CATEGORY]: CATEGORIES,
        [SUBCATEGORY]: SUBCATEGORIES,
        [ITEM]: ITEMS,
        [TYPE_OR_COLOR]: TYPES_OR_COLORS,
      };
      // change last section
      setLastSection(sectionMap[field]);

      setShouldShowError(false);
    };

  /**
   * Resets the form by clearing the form selections and certain form options,
   * and setting the current section back to CATEGORIES.
   *
   * This function does the following:
   * - Sets the current section back to CATEGORIES(first page of the form).
   * - Clears all the form selections by setting them to an empty string.
   * - Resets the form options for SUBCATEGORIES, ITEMS, and TYPES_OR_COLORS by setting them to an empty array.
   */
  const resetForm = () => {
    setCurrentSection(CATEGORIES);
    setFormSelections({ [CATEGORY]: '', [SUBCATEGORY]: '', [ITEM]: '', [TYPE_OR_COLOR]: '' });
    setFormOptions({ ...formOptions, [SUBCATEGORIES]: [], [ITEMS]: [], [TYPES_OR_COLORS]: [] });
  };

  const displayTextTypeOrColor = () => {
    const { [CATEGORY]: itemCategory, [SUBCATEGORY]: itemSubcategory, [ITEM]: itemType } = formSelections;

    switch (true) {
      case !!PRODUCTS_DATA[itemCategory]?.[itemSubcategory]?.[itemType]?.type:
        return ' type';
      case !!PRODUCTS_DATA[itemCategory]?.[itemSubcategory]?.[itemType]?.color:
        return ' color';
      default:
        return ' type/color';
    }
  };

  return (
    <GuideContext.Provider
      value={{
        formSelections,
        setFormSelections,
        formOptions,
        setFormOptions,
        isFormSubmitted,
        setIsFormSubmitted,
        isFormModalOpen,
        setIsFormModalOpen,
        isSignUpValidatorModalOpen,
        shouldShowError,
        setShouldShowError,
        windowWidth,
        currentSection,
        setCurrentSection,
        lastSection,
        isFirstStep: currentSection === CATEGORIES,
        isLastStep: currentSection === SECTIONS_ARRAY[SECTIONS_ARRAY.length - 1],
        goToNextSection,
        goToPreviousSection,
        openFormModal,
        closeFormModal,
        resetForm,
        updateFormOptionsAndFormSelections,
        displayTextTypeOrColor,
        closeSignUpValidatorModal,
      }}
    >
      {children}
    </GuideContext.Provider>
  );
};

export { GuideProvider };
