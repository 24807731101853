import React from 'react';
import ACCESSORIES_ICON from '../../assets/icons/Geaux_Get_It/geaux-accessories.png';
import BACKPACK_ICON from '../../assets/icons/Geaux_Get_It/geaux-backpack.png';
import BAGS_ICON from '../../assets/icons/Geaux_Get_It/geaux-bags.png';
import BEAUTY_ICON from '../../assets/icons/Geaux_Get_It/geaux-beauty.png';
import BLAZER_ICON from '../../assets/icons/Geaux_Get_It/geaux-blazer.png';
import BRIEFCASE_ICON from '../../assets/icons/Geaux_Get_It/geaux-briefcase.png';
import CLOTHING_ICON from '../../assets/icons/Geaux_Get_It/geaux-clothing.png';
import COLORWHEEL_ICON from '../../assets/icons/Geaux_Get_It/geaux-colorwheel.png';
import CONSUMER_ICON from '../../assets/icons/Geaux_Get_It/geaux-consumer.png';
import DESTINATION_ICON from '../../assets/icons/Geaux_Get_It/geaux-destination.png';
import DRESS_ICON from '../../assets/icons/Geaux_Get_It/geaux-dress.png';
import DUFFLE_ICON from '../../assets/icons/Geaux_Get_It/geaux-duffle.png';
import ELECTRONICS_ICON from '../../assets/icons/Geaux_Get_It/geaux-electronics.png';
import FURNITURE_ICON from '../../assets/icons/Geaux_Get_It/geaux-furniture.png';
import HOODIE_ICON from '../../assets/icons/Geaux_Get_It/geaux-hoodie.png';
import HOUSEHOLD_ICON from '../../assets/icons/Geaux_Get_It/geaux-household.png';
import JACKET_ICON from '../../assets/icons/Geaux_Get_It/geaux-jacket.png';
import KITCHEN_ICON from '../../assets/icons/Geaux_Get_It/geaux-kitchen.png';
import OUTFIT_ICON from '../../assets/icons/Geaux_Get_It/geaux-outfit.png';
import PANTS_ICON from '../../assets/icons/Geaux_Get_It/geaux-pants.png';
import SHIRT_ICON from '../../assets/icons/Geaux_Get_It/geaux-shirt.png';
import SHORTS_ICON from '../../assets/icons/Geaux_Get_It/geaux-shorts.png';
import SINGLESTRAP_ICON from '../../assets/icons/Geaux_Get_It/geaux-singlestrap.png';
import SKIRT_ICON from '../../assets/icons/Geaux_Get_It/geaux-skirt.png';
import SWIMWEAR_ICON from '../../assets/icons/Geaux_Get_It/geaux-swimwear.png';
import TOTE_ICON from '../../assets/icons/Geaux_Get_It/geaux-tote.png';
import VOTE_ICON from '../../assets/icons/Geaux_Get_It/geaux-vote.png';
import WEARABLES_ICON from '../../assets/icons/Geaux_Get_It/geaux-wearables.png';

/**
 * this is the data structure for the cart items
 * const PRODUCTS_DATA = {
 *   Categories: {
 *     SubCategories: {
 *       items: {
 *         TypeOrColorIfAny: [],
 *       },
 *     },
 *   },
 * };
 */

export const CATEGORIES = 'CATEGORIES';
export const SONGCATEGORIES = 'SONGCATEGORIES';
export const CATEGORY = 'CATEGORY';
export const SUBCATEGORIES = 'SUBCATEGORIES';
export const SUBCATEGORY = 'SUBCATEGORY';
export const ITEMS = 'ITEMS';
export const ITEM = 'ITEM';
export const TYPES = 'TYPES';
export const TYPE = 'TYPE';
export const COLORS = 'COLORS';
export const COLOR = 'COLOR';
export const TYPES_OR_COLORS = 'TYPES_OR_COLORS';
export const TYPE_OR_COLOR = 'TYPE_OR_COLOR';
export const SUCCESS = 'SUCCESS';
export const ICON = 'ICON';
export const SECTIONS_ARRAY = [CATEGORIES, SUBCATEGORIES, ITEMS, TYPES_OR_COLORS];
export const CUSTOM = 'CUSTOM';

const GENERAL_COLORS = {
  Red: 'hsl(0, 90%, 35%)',
  Orange: 'hsl(26, 98%, 40%)',
  Yellow: 'hsl(47,95%,67%)',
  Green: 'hsl(128,33%,50%)',
  Cyan: 'hsl(204,88%,71%)',
  Blue: 'hsl(207, 100%, 51%)',
  Navy: 'hsl(221,29%,32%)',
  Purple: 'hsl(287,26%,40%)',
  Pink: 'hsl(341,76%,70%)',
  Beige: 'hsl(40, 73%, 84%)',
  Tan: 'hsl(43, 78%, 91%)',
  Brown: 'hsl(13, 41%, 38%)',
  Grey: 'hsl(0, 0%, 35%)',
  Black: 'hsl(0, 0%, 0%)',
  White: 'hsl(0, 0%, 100%)',
  Pattern: CUSTOM,
  MultiColor: CUSTOM,
};

const FURNITURE_COLORS = {
  Red: 'hsl(0, 90%, 35%)',
  Orange: 'hsl(26, 98%, 40%)',
  Yellow: 'hsl(47,95%,67%)',
  Green: 'hsl(128,33%,50%)',
  Cyan: 'hsl(204,88%,71%)',
  Blue: 'hsl(207, 100%, 51%)',
  Navy: 'hsl(221,29%,32%)',
  Purple: 'hsl(287,26%,40%)',
  Pink: 'hsl(341,76%,70%)',
  Beige: 'hsl(40, 73%, 84%)',
  Tan: 'hsl(43, 78%, 91%)',
  Brown: 'hsl(13, 41%, 38%)',
  Grey: 'hsl(0, 0%, 35%)',
  Black: 'hsl(0, 0%, 0%)',
  White: 'hsl(0, 0%, 100%)',
  Pattern: CUSTOM,
  MultiColor: CUSTOM,
  // Suede: CUSTOM,
  // Leather: CUSTOM,
};

const MAKE_UP_COLORS = {
  Clear: 'hsl(0, 0%, 100%)',
  Pink: 'hsl(350, 100%, 88%)',
  Peach: 'hsl(39, 100%, 85%)',
  Rose: 'hsl(340, 76%, 65%)',
  Nude: 'hsl(30, 20%, 75%)',
  Red: 'hsl(0, 100%, 50%)',
  Mauve: 'hsl(300, 50%, 60%)',
  Coral: 'hsl(16, 100%, 66%)',
  Berry: 'hsl(345, 75%, 40%)',
  Fuchsia: 'hsl(320, 100%, 50%)',
  Plum: 'hsl(300, 100%, 25%)',
  Caramel: 'hsl(36, 100%, 50%)',
  Wine: 'hsl(350, 50%, 40%)',
  Chocolate: 'hsl(25, 75%, 35%)',
  Champagne: 'hsl(55, 80%, 85%)',
  Gold: 'hsl(51, 100%, 50%)',
};

export const PRODUCTS_DATA = {
  Vote: {
    [ICON]: <img src={VOTE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      { [SUBCATEGORY]: 'SONG 1', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 2', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 3', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 4', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 5', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 6', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 7', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 8', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 9', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 10', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 11', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 12', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 13', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 14', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 15', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 16', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 17', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 18', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 19', [ICON]: '' },
      { [SUBCATEGORY]: 'SONG 20', [ICON]: '' },
    ],
  },
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //*  :::::: CLOTHING : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Clothing: {
    [ICON]: <img src={CLOTHING_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      {
        [SUBCATEGORY]: 'Outfit',
        [ICON]: <img src={OUTFIT_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
      },
      {
        [SUBCATEGORY]: 'Shirt',
        [ICON]: <img src={SHIRT_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Silk', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Flannel', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Poplin', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Pants',
        [ICON]: <img src={PANTS_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Denim', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Khaki', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Dress',
        [ICON]: <img src={DRESS_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Silk', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Flannel', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Poplin', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Shorts',
        [ICON]: <img src={SHORTS_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Denim', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Khaki', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Skirt',
        [ICON]: <img src={SKIRT_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Denim', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Khaki', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Hoodie',
        [ICON]: <img src={HOODIE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Silk', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Flannel', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Poplin', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Jacket',
        [ICON]: <img src={JACKET_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Silk', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Flannel', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Poplin', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Blazer',
        [ICON]: <img src={BLAZER_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Cotton', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Linen', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Silk', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Flannel', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Poplin', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Swimwear',
        [ICON]: <img src={SWIMWEAR_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Nylon', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Polyester', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Spandex', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Neoprene', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Microfiber', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
    ],
  },

  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //* :::::: ACCESSORIES : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Accessories: {
    [ICON]: <img src={ACCESSORIES_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      {
        [SUBCATEGORY]: 'Wearables',
        [ICON]: <img src={WEARABLES_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Necklace', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Earring', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Wedding Ring', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Decorative Ring', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Watch', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Bracelet', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Hair decor', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Waist Jewelry', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Bags',
        [ICON]: <img src={BAGS_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          {
            [ITEM]: 'Single Strap',
            [ICON]: <img src={SINGLESTRAP_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
            [COLORS]: GENERAL_COLORS,
          },
          {
            [ITEM]: 'Backpack',
            [ICON]: <img src={BACKPACK_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
            [COLORS]: GENERAL_COLORS,
          },
          {
            [ITEM]: 'Tote',
            [ICON]: <img src={TOTE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
            [COLORS]: GENERAL_COLORS,
          },
          {
            [ITEM]: 'Duffle',
            [ICON]: <img src={DUFFLE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
            [COLORS]: GENERAL_COLORS,
          },
          {
            [ITEM]: 'Briefcase',
            [ICON]: <img src={BRIEFCASE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
            [COLORS]: GENERAL_COLORS,
          },
        ],
      },
      {
        [SUBCATEGORY]: 'Headwear',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Caps', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Hats', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Beanies', [ICON]: '', [COLORS]: GENERAL_COLORS },
          { [ITEM]: 'Bandanas', [ICON]: '', [COLORS]: GENERAL_COLORS },
        ],
      },
    ],
  },

  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //* :::::: FURNITURE : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Furniture: {
    [ICON]: <img src={FURNITURE_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      {
        [SUBCATEGORY]: 'Living Room',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Entire Set', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Sofa', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Chair', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Coffee Table', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Rug', [ICON]: '', [COLORS]: FURNITURE_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Dining Room',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Entire Set', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Table', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Chairs', [ICON]: '', [COLORS]: FURNITURE_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Bedroom',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Entire Set', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Bed', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Nightstand', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Mattress', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Pillow', [ICON]: '', [COLORS]: FURNITURE_COLORS },
          { [ITEM]: 'Bedding', [ICON]: '', [COLORS]: FURNITURE_COLORS },
        ],
      },
      {
        [SUBCATEGORY]: 'Leisure Room',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Pool Table', [ICON]: '' },
          { [ITEM]: 'Game Table', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Window',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Window Treatment', [ICON]: '' },
          { [ITEM]: 'Window Blinds', [ICON]: '' },
          { [ITEM]: 'Window Curtains', [ICON]: '' },
        ],
      },
    ],
  },

  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //* :::::: BEAUTY : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Beauty: {
    [ICON]: <img src={BEAUTY_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      {
        [SUBCATEGORY]: 'Makeup',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Whole Face', [ICON]: '' },
          { [ITEM]: 'Palette Kit', [ICON]: '' },
          { [ITEM]: 'Lipstick', [ICON]: '', [COLORS]: MAKE_UP_COLORS },
          { [ITEM]: 'Lip Gloss', [ICON]: '', [COLORS]: MAKE_UP_COLORS },
          { [ITEM]: 'Eyelashes', [ICON]: '' },
          { [ITEM]: 'Mascara', [ICON]: '', [COLORS]: MAKE_UP_COLORS },
          { [ITEM]: 'Eyeliner', [ICON]: '', [COLORS]: MAKE_UP_COLORS },
          { [ITEM]: 'Eyeliner Remover', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Eye Care',
        [ICON]: '',
        [ITEMS]: [
          {
            [ITEM]: 'Glasses',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Sunglasses', [ICON]: '' },
              { [TYPE]: 'Reading', [ICON]: '' },
              { [TYPE]: 'Frames', [ICON]: '' },
              { [TYPE]: 'Lenses', [ICON]: '' },
              { [TYPE]: 'Tints', [ICON]: '' },
              { [TYPE]: 'Decorative', [ICON]: '' },
            ],
          },
          {
            [ITEM]: 'Contacts',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Prescription', [ICON]: '' },
              { [TYPE]: 'Decorative', [ICON]: '' },
            ],
          },
        ],
      },
      {
        [SUBCATEGORY]: 'Hair Care',
        [ICON]: '',
        [ITEMS]: [
          {
            [ITEM]: 'Texture',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Type 1A Straight Hair', [ICON]: '' },
              { [TYPE]: 'Type 1B Straight Hair', [ICON]: '' },
              { [TYPE]: 'Type 1C Straight Hair', [ICON]: '' },
              { [TYPE]: 'Type 2A Wavy Hair', [ICON]: '' },
              { [TYPE]: 'Type 2B Wavy Hair', [ICON]: '' },
              { [TYPE]: 'Type 2C Wavy Hair', [ICON]: '' },
              { [TYPE]: 'Type 3A Curly Hair', [ICON]: '' },
              { [TYPE]: 'Type 3B Curly Hair', [ICON]: '' },
              { [TYPE]: 'Type 3C Curly Hair', [ICON]: '' },
              { [TYPE]: 'Type 4A Coily Hair', [ICON]: '' },
              { [TYPE]: 'Type 4B Coily Hair', [ICON]: '' },
              { [TYPE]: 'Type 4C Coily Hair', [ICON]: '' },
            ],
          },
          { [ITEM]: 'Shampoo', [ICON]: '' },
          { [ITEM]: 'Conditioner', [ICON]: '' },
          { [ITEM]: 'Protective Styles', [ICON]: '' },
          {
            [ITEM]: 'Extensions',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Short', [ICON]: '' },
              { [TYPE]: 'Long', [ICON]: '' },
              { [TYPE]: 'Sew In', [ICON]: '' },
              { [TYPE]: 'Glue', [ICON]: '' },
            ],
          },
          {
            [ITEM]: 'Weave',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Singles', [ICON]: '' },
              { [TYPE]: 'Bundles', [ICON]: '' },
            ],
          },
          {
            [ITEM]: 'Wigs',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Cap', [ICON]: '' },
              { [TYPE]: 'Lace Front', [ICON]: '' },
            ],
          },
          { [ITEM]: 'Jewelry', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Skin Care',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Full Regiment', [ICON]: '' },
          {
            [ITEM]: 'Creams',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Sunscreen', [ICON]: '' },
              { [TYPE]: 'Hypoallergenic', [ICON]: '' },
              { [TYPE]: 'Skin Repair', [ICON]: '' },
            ],
          },
          { [ITEM]: 'Ointments', [ICON]: '' },
          { [ITEM]: 'Toners', [ICON]: '' },
          { [ITEM]: 'Extraction Kits', [ICON]: '' },
          { [ITEM]: 'Dermatologist Centers', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Oral Care',
        [ICON]: '',
        [ITEMS]: [
          { [ITEM]: 'Whole Routine', [ICON]: '' },
          { [ITEM]: 'Toothbrush', [ICON]: '' },
          { [ITEM]: 'Toothpaste', [ICON]: '' },
          { [ITEM]: 'Floss Utensils', [ICON]: '' },
          {
            [ITEM]: 'Teeth Whitening',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Kits', [ICON]: '' },
              { [TYPE]: 'Procedure', [ICON]: '' },
            ],
          },
        ],
      },
      {
        [SUBCATEGORY]: 'Nails',
        [ICON]: '',
        [ITEMS]: [
          {
            [ITEM]: 'Polish',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Gel', [ICON]: '' },
              { [TYPE]: 'Basic', [ICON]: '' },
              { [TYPE]: 'Lacquer', [ICON]: '' },
              { [TYPE]: 'Breathable', [ICON]: '' },
              { [TYPE]: 'Dip Powder', [ICON]: '' },
              { [TYPE]: 'Poly Gel', [ICON]: '' },
              { [TYPE]: 'Shellac', [ICON]: '', [COLORS]: MAKE_UP_COLORS },
            ],
          },
          {
            [ITEM]: 'Nail',
            [ICON]: '',
            [TYPES]: [
              { [TYPE]: 'Press On', [ICON]: '' },
              { [TYPE]: 'Acrylic', [ICON]: '' },
              { [TYPE]: 'Other', [ICON]: '' },
            ],
          },
        ],
      },
    ],
  },

  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //* :::::: ELECTRONICS : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Electronics: {
    [ICON]: <img src={ELECTRONICS_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      {
        [SUBCATEGORY]: 'Consumer',
        [ICON]: <img src={CONSUMER_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Phone', [ICON]: '' },
          { [ITEM]: 'Laptop', [ICON]: '' },
          { [ITEM]: 'TV', [ICON]: '' },
          { [ITEM]: 'Camera', [ICON]: '' },
          { [ITEM]: 'Microphone', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Household',
        [ICON]: <img src={HOUSEHOLD_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Washer', [ICON]: '' },
          { [ITEM]: 'Dryer', [ICON]: '' },
          { [ITEM]: 'Vacuum', [ICON]: '' },
          { [ITEM]: 'Lamps', [ICON]: '' },
          { [ITEM]: 'Security Systems', [ICON]: '' },
          { [ITEM]: 'Temp Control', [ICON]: '' },
        ],
      },
      {
        [SUBCATEGORY]: 'Kitchen',
        [ICON]: <img src={KITCHEN_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
        [ITEMS]: [
          { [ITEM]: 'Fridge', [ICON]: '' },
          { [ITEM]: 'Stove', [ICON]: '' },
          { [ITEM]: 'Dishwasher', [ICON]: '' },
          { [ITEM]: 'Freezer', [ICON]: '' },
          { [ITEM]: 'Microwave', [ICON]: '' },
        ],
      },
    ],
  },

  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  //* :::::: DESTINATION : :  :   :    :     :        :          :
  //* ────────────────────────────────────────────────────────────────────────────────────────────────────
  Destination: {
    [ICON]: <img src={DESTINATION_ICON} alt='Vote' style={{ width: 40, height: 50, paddingBottom: 10 }} />,
    [SUBCATEGORIES]: [
      { [SUBCATEGORY]: 'Whole Trip', [ICON]: '' },
      { [SUBCATEGORY]: 'Date Night', [ICON]: '' },
      { [SUBCATEGORY]: 'Restaurant', [ICON]: '' },
      { [SUBCATEGORY]: 'Event', [ICON]: '' },
      { [SUBCATEGORY]: 'Hotel', [ICON]: '' },
      { [SUBCATEGORY]: 'Experience', [ICON]: '' },
      { [SUBCATEGORY]: 'Airline', [ICON]: '' },
      { [SUBCATEGORY]: 'Location', [ICON]: '' },
    ],
  },
};
