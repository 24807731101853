import React, { createContext, useRef, useState, useEffect } from 'react';
import { AGE_RATING, data } from 'database/index';
import { getVideoId, setVideoInfo } from 'services/channelInfo';
import { connect } from 'react-redux';
import { get, set } from 'lodash';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getDoc} from 'firebase/firestore';
export const ChannelPopupContext = createContext();

const mapStateProps = (state) => ({
  currentVideoId: getVideoId(state),
});

const mapDispatchProps = {
  setVideoInfo,
};

const ChannelPopupProvider = ({ children, setVideoInfo }) => {
  /*
   * The 16+ channels are:
   * - Short Films (16+) (Discover)
   * - BlindKnowledge (16+) (Music)
   * - Beat Club (16+) (Music)
   * - XSET (16+) (Science/Tech)
   */
  const auth = getAuth();
  const user = auth.currentUser;
  const usersCollectionRef = collection(db, 'users');

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       const userDocRef = doc(db, 'users', user.uid);
  //       const userDoc = await getDocs(userDocRef);
  //       userDoc.forEach((doc) => {
  //         let userData = doc.data();
  //         if (userData.isOver16) {
  //           localStorage.setItem('isOver16', 'true');
  //           console.log('Firebase data: isOver16 is true');
  //         } 

  //     }
  //     )
  //   }
  //   });

  //   return () => unsubscribe();
  // }, []);

  const overSixteenChannelsArray = Object.values(data)
    .flat()
    .filter((channel) => AGE_RATING in channel)
    .map((channel) => channel.episodes[0].videoId);

  const [isChannelInfoOverlayOpen, setIsChannelInfoOverlayOpen] = useState(false);
  const [isAgeVerificationPopupOpen, setIsAgeVerificationAgePopupOpen] = useState(false);
  const [selectedOverSixteenChannel, setSelectedOverSixteenChannel] = useState();
  const [isOver16, setIsOver16] = useState(false);

  const handleAgeVerification = async (selectedChannel) => {
    if (!overSixteenChannelsArray.includes(selectedChannel.videoId)) return handleChangeVideo(selectedChannel);
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.isOver16) {
          return handleChangeVideo(selectedChannel);
        } else {
          return setIsAgeVerificationAgePopupOpen(true); // 如果 Firestore 里面标记为不满16岁
        }
      } else {
        // Firestore 中没有用户文档的情况
        setIsAgeVerificationAgePopupOpen(true);
        setIsChannelInfoOverlayOpen(false);
        setSelectedOverSixteenChannel(selectedChannel);
      }
    }
  // } else {
  //   // 用户未登录，使用 localStorage
  //   if (localStorage.getItem('isOver16') === 'true') {
  //     return handleChangeVideo(selectedChannel);
  //   }
  //   if (localStorage.getItem('isOver16') === 'false') {
  //     return setIsAgeVerificationAgePopupOpen(true);
  //   }
  // }
    
    
  
    setIsAgeVerificationAgePopupOpen(true);
    setIsChannelInfoOverlayOpen(false);
    setSelectedOverSixteenChannel(selectedChannel);
  
  };

  const overLayTimeout = useRef();
  const handleChangeVideo = (channel) => {
    setIsChannelInfoOverlayOpen(true);
    setVideoInfo(channel);
    clearTimeout(overLayTimeout.current);
    overLayTimeout.current = setTimeout(() => {
      setIsChannelInfoOverlayOpen(false);
    }, 10000);
  };

  const handleConfirmOver16 = async () => {
    if (user){
      
      // const userDoc = await getDocs(usersCollectionRef);
      //   userDoc.forEach((docu) => {
      //     let userData = docu.data();
      //     const userRef = doc(db, "users", docu.id);
      //     if (userData.isOver16 == null) {
      //       addDoc(userRef, { isOver16: true });
      //     }else{
            
      //       updateDoc(userRef, { isOver16: true });
      //     }
      //   }
      //   )
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, { isOver16: true } );
    }
    setIsAgeVerificationAgePopupOpen(false);
    localStorage.setItem('isOver16', 'true');
    handleChangeVideo(selectedOverSixteenChannel);
  };

  const handleCloseChannelPopup = () => {
    setIsAgeVerificationAgePopupOpen(false);
    localStorage.setItem('isOver16', 'false');
    // localStorage.removeItem('isOver16'); // 在用户注销时移除年龄验证状态
  };

  const proceedToChannel = () => {
    handleChangeVideo(selectedOverSixteenChannel);
  };
  
  return (
    <ChannelPopupContext.Provider
      value={{
        isChannelInfoOverlayOpen,
        setIsChannelInfoOverlayOpen,
        isAgeVerificationPopupOpen,
        setIsAgeVerificationAgePopupOpen,
        overSixteenChannelsArray,
        handleAgeVerification,
        handleConfirmOver16,
        handleCloseChannelPopup,
        proceedToChannel,
      }}
    >
      {children}
    </ChannelPopupContext.Provider>
  );
};


export default connect(mapStateProps, mapDispatchProps)(ChannelPopupProvider);