import React from 'react';

export const FormSvgIcon = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        id='Layer_3'
        height='512'
        viewBox='0 0 64 64'
        width='512'
        data-name='Layer 3'
      >
        <path
          d='m60.407 22.791a4.031 4.031 0 0 1 1.593 3.209v33a3 3 0 0 1 -3 3h-54a3 3 0 0 1 -3-3v-33a4.031 4.031 0 0 1 1.593-3.209l26.607-20.191a2.974 2.974 0 0 1 1.8-.6 2.974 2.974 0 0 1 1.8.6z'
          fill={'hsl(32,93%,48%)'}
        />
        <path
          d='m60.407 22.791-26.607-20.191a2.975 2.975 0 0 0 -3.594 0l-22.376 16.977a4.015 4.015 0 0 0 -.83 2.423v33a3 3 0 0 0 3 3h36a2 2 0 0 1 0 4h13a3 3 0 0 0 3-3v-33a4.031 4.031 0 0 0 -1.593-3.209zm-29.407 30.209a12 12 0 1 1 12-12 12 12 0 0 1 -12 12z'
          fill={'hsl(40,97%,49%)'}
        />
        <path
          d='m32 41a3.8 3.8 0 0 0 1.87-.493l27.888-15.807a4.006 4.006 0 0 0 -1.351-1.913l-26.607-20.187a2.975 2.975 0 0 0 -3.594 0l-26.613 20.191a4.006 4.006 0 0 0 -1.351 1.909l27.888 15.8a3.8 3.8 0 0 0 1.87.5z'
          fill={'hsl(28,84%,44%)'}
        />
        <path
          d='m55 9v19.53l-21.13 11.98a3.813 3.813 0 0 1 -3.74 0l-21.13-11.98v-19.53a3 3 0 0 1 3-3h40a3 3 0 0 1 3 3z'
          fill={'hsl(0,0%,80%)'}
        />
        <path
          d='m52 6h-40v16.385a9.746 9.746 0 0 0 4.858 8.431l13.272 7.694a3.813 3.813 0 0 0 3.74 0l21.13-11.98v-17.53a3 3 0 0 0 -3-3z'
          fill={'hsl(0,0%,95%)'}
        />
        <path d='m41 53h18v2h-18z' fill={'#fff'} />
        <path d='m35 57h24v2h-24z' fill={'#fff'} />
        <g fill={'#ccc'}>
          <path d='m12 19.001h40v2h-40z' />
          <path d='m12 22.999h40v2h-40z' />
          <path d='m12 27h10v2h-10z' />
          <path d='m17.999 11h28v2h-28z' />
          <path d='m12 14.999h40v2h-40z' />
        </g>
        <circle cx='32' cy='40' fill={'hsl(144,70%,24%)'} r='12' />
        <path
          d='m32 28a11.933 11.933 0 0 0 -7.2 2.416 11.99 11.99 0 0 0 16.5 17.168 12 12 0 0 0 -9.3-19.584z'
          fill={'hsl(127,46%,38%)'}
        />
        <path d='m37 34-7 7-3-3-3 3 6 6 10-10z' fill={'hsl(0,0%,100%)'} />
      </svg>
    </>
  );
};
