import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { init } from '../../store';
import Routes from '../../pages/Routes';
import ReactGA from 'react-ga';

const Root = (props) => {
  useEffect(() => {
    ReactGA.initialize('UA-128505641-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const store = init(props.reducers);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
};

export default Root;
