import React, { useState } from 'react';
import SignupNavbar from 'components/SignupNavbar';
import { useLanguage } from 'components/Translate/LanguageContext';
import Footer from 'components/Footer';
import { apiKey } from '../../../config.js';
import { SIGN_IN_ROUTE, RESET_PASSWORD_ROUTE } from 'pages/Routes';
import { Translator, Translate } from 'react-auto-translate';
import RESET_EMAIL from '../../assets/icons/Forgot_Password/reset-email.png';
import './EmailSent.scss';

// This component is for when a user sends a password reset request on the forgot password page
// This lets the user know they can go to their email and visit the link to reset their password
const EmailSent = () => {
  const { language, changeLanguage } = useLanguage();

  // Sends user back to the sign in page(this can be changed later if needed)
  const redirectToSignIn = () => {
    window.location.href = SIGN_IN_ROUTE;
  };

  return (
    <Translator from='en' to={language} googleApiKey={apiKey}>
      <div className='signin-page-container'>
        <SignupNavbar />
        <div className='signin-boxi'>
          <img src={RESET_EMAIL} alt='email' className='email-icon' />
          <h1 className='reset-header'>
            <Translate>Reset Link Sent!</Translate>
          </h1>
          <p className='reset-subheader'>
            <Translate>Check your email for a password reset link</Translate>
          </p>
          <button className='ok-button' onClick={() => redirectToSignIn()}>
            <Translate>Ok</Translate>
          </button>
        </div>
        <Footer />
      </div>
    </Translator>
  );
};

export default EmailSent;
