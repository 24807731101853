// Mock Database with some JSON data
import AudioAction from '../assets/icons/Channel_Logos/AudioAction.png';
import AudioTalk from '../assets/icons/Channel_Logos/AudioTalk.png';
import Dive from '../assets/icons/Channel_Logos/Dive.png';
import Expand from '../assets/icons/Channel_Logos/Expand.png';
import Expressions from '../assets/icons/Channel_Logos/Expressions.png';
import Fantasy from '../assets/icons/Channel_Logos/Fantasy.png';
import GeauxGetIt from '../assets/icons/Channel_Logos/GeauxGetIt.png';
import beatclub from '../assets/icons/Channel_Logos/beatclub.png';
import eco from '../assets/icons/Channel_Logos/eco.png';
import gx from '../assets/icons/Channel_Logos/gx.png';
import gml from '../assets/icons/Channel_Logos/gml.png';
import kids from '../assets/icons/Channel_Logos/kids.png';
import gaming from '../assets/icons/Channel_Logos/gaming.png';
import ShortFilms from '../assets/icons/Channel_Logos/ShortFilms.png';
import Travel from '../assets/icons/Channel_Logos/Travel.png';
import studio from '../assets/icons/Channel_Logos/studio.png';
import blind from '../assets/icons/Channel_Logos/blind.png';
import ctrl from '../assets/icons/Channel_Logos/ctrl.png';
import reccap from '../assets/icons/Channel_Logos/reccap.png';

export const AGE_RATING = 'ageRating';

const data = {
  Featured: [
    {
      number: 101,
      channelName: 'Geaux Info',
      channelIcon: gx,
      channelDescription: 'Watch here to learn  the "how-tos" and the latest about the network and what we have in store!',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=8evbsrzeyeck&is_link=1&w=1080&h=720&pause=0&title=Welcome&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'GeauxInfo',
        },
      ],
    },
    {
      number: 200,
      channelName: 'Geaux Get It!',
      channelIcon: GeauxGetIt,
      channelDescription: "Here's the channel giving you the insights you need for all things startup. ",
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=dylf63ysmvc4&is_link=1&w=1080&h=720&pause=0&title=GeauxBusiness&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Business/Entrepreneurship',
        },
      ],
    },
    {
      number: 17,
      channelName: 'Studio',
      channelIcon: studio,
      channelDescription:
        'Studio 17 TV is your window into the people, food and culture of Central Florida. Orlando is more than just theme parks, let us show you!',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=23x66k2oa90k &is_link=1&w=1080&h=720&pause=0&title=Welcome&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Studio 17',
        },
      ],
    },
    {
      number: 212,
      channelName: 'Control',
      channelIcon: ctrl,
      channelDescription: 'CTRL: Fresh Drops of Culture, Nonstop Vibes created by the crew at Dopely Studio NYC',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=2duojow2u6m8&is_link=1&w=1080&h=720&pause=0&title=Studio&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Control',
        },
      ],
    },
  ],
  Kids: [
    {
      number: 100,
      channelName: 'Geaux Kids',
      channelIcon: kids,
      channelDescription: 'Come here for all things Geaux Kids. From original cartoons to shows from around the world, this channel is sure to our younger audience or those "young at heart", engaged for hours on end',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=4k5rhewj81a8&is_link=1&w=1080&h=720&pause=0&title=GeauxKids&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Geaux Kids',
        },
      ],
    },
  ],
  'Business + News': [
    {
      number: 200,
      channelName: 'Geaux Get It!',
      channelIcon: GeauxGetIt,
      channelDescription: "Here's the channel giving you the insights you need for all things startup. ",
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=dylf63ysmvc4&is_link=1&w=1080&h=720&pause=0&title=GeauxBusiness&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Business/Entrepreneurship',
        },
      ],
    },
  ],
  Discover: [
    {
      number: 300,
      channelName: 'Expressions',
      channelIcon: Expressions,
      channelDescription: 'Art, DIYs, and everything creative can be found on this channel focused on the expressions of our inner passions and interests',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=ezex445346go&is_link=1&w=1080&h=720&pause=0&title=Expressions&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Art/Creativity',
        },
      ],
    },
    {
      number: 301,
      channelName: 'Expand',
      channelIcon: Expand,
      channelDescription: 'A channel focused on providing you with intelligent content that will hopefully spark curiosity and ignite a passion to seek out "more"',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=4h3x01v03ri8&is_link=1&w=1080&h=720&pause=0&title=Expand&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Knowledge/Education',
        },
      ],
    },
    {
      number: 302,
      channelName: 'Short Films (16+)',
      channelIcon: ShortFilms,
      channelDescription: 'A collection of short films and movies from around the world created by award winning directors and some new comers too. For submissions, send to content@geaux.tv',
      channelLink: 'https://geaux.tv/creators',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it= ba4pf76csb48&is_link=1&w=1080&h=720&pause=0&title=ShortFilms&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Short Films',
        },
      ],
      [AGE_RATING]: 16,
    },
    {
      number: 303,
      channelName: 'Geaux Travel',
      channelIcon: Travel,
      channelDescription: 'Watch what is it like to travel to every country in the world',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=pcb9jmxgyggs&is_link=1&w=1080&h=720&pause=0&title=Travel&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Travel',
        },
      ],
    },
    {
      number: 309,
      channelName: 'RecCap',
      channelIcon: reccap,
      channelDescription:
        'From comics books, to video games and everything in between, The channel brings 90s kid energy to discover new products and experiences',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it= 67mqs6padhc0&is_link=1&w=1080&h=720&pause=0&title=RecCap&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'RecCap',
        },
      ],
    },
  ],
  Music: [
    {
      number: 400,
      channelName: 'Audio Dramas Action',
      channelIcon: AudioAction,
      channelDescription: 'Action, Mystery, Drama and more. Get lost in all the soundscapes of great adventures from incredible story tellers',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=33q37v9x196o&is_link=1&w=1080&h=720&pause=0&title=AudioDramas&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Audio Dramas Action',
        },
      ],
    },
    {
      number: 401,
      channelName: 'Audio Dramas Talk',
      channelIcon: AudioTalk,
      channelDescription: 'Audio Dramas Talk',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=bcxwe5fmqzcc&is_link=1&w=1080&h=720&pause=0&title=AudioDramasTalk&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Audio Dramas Talk',
        },
      ],
    },
    {
      number: 411,
      channelName: 'Blind Knowledge (16+)',
      channelIcon: blind,
      channelDescription:
        'A mashup of all content that is featured, distributed, and promoted via the Blind Knowledge Network',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=6xrzqbdpsdgk &is_link=1&w=1080&h=720&pause=0&title=Studio&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'BlindKnowledge',
        },
      ],
      [AGE_RATING]: 16,
    },
    {
      number: 460,
      channelName: 'Beat Club (16+)',
      channelDescription:
        'The Stew is a culturally-curated umbrella housing developmental platform for producers and artists to connect and cook up fresh music through a variety of niche outlets',
      channelIcon: beatclub,
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=6wi00w1avyww&is_link=1&w=1080&h=720&pause=0&title=BeatClub&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Beat Club Podcast',
        },
      ],
      [AGE_RATING]: 16,
    },
    {
      number: 464,
      channelName: 'GML',
      channelIcon: gml,
      channelDescription: '',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=zimgl2z6v6sw&is_link=1&w=1080&h=720&pause=0&title=Studio&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Geaux Music Live',
        },
      ],
    },
    {
      number: 465,
      channelName: 'The Dive',
      channelIcon: Dive,
      channelDescription: 'Our Global music video channel featuring the Geaux Music Live countdown show',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it=d6avm98x9uok&is_link=1&w=1080&h=720&pause=0&title=TheDive&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3920158%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none&_ga=2.1183729.1905814336.1621193399-54578216.1619214486',
          title: 'Geaux Music Live',
        },
      ],
    },
  ],
  'Science + Tech': [
    {
      number: 500,
      channelName: 'Geaux Fantasy',
      channelIcon: Fantasy,
      channelDescription: 'Fantasy//SciFi/Fiction',
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it= cz51jew0dz40&is_link=1&w=1080&h=720&pause=0&title=Fantasy&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'Fantasy/Fiction',
        },
      ],
    },
    {
      number: 501,
      channelName: 'Ecopreneur',
      channelDescription:
        'Welcome to the Ecopreneur Channel! Home of The EcoSpotlight Show and all things sustainability conceived Co-Founder Philip Toussaint. Click the info button to learn more!',
      channelIcon: eco,
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it= 1zuiws29tz6s &is_link=1&w=1080&h=720&pause=0&title=Ecopreneur&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: 'EcoSpotlight',
        },
      ],
    },
    {
      number: 502,
      channelName: 'GeauxGaming (16+)',
      channelDescription: "Gaming Remix'd. The fastest growing & most diverse gaming lifestyle brand in the world",
      channelIcon: gaming,
      episodes: [
        {
          videoId:
            'https://play.streamingvideoprovider.com/popplayer.php?it= anczltfqljk8&is_link=1&w=1080&h=720&pause=0&title=Welcome&skin=3&repeat=&brandNW=1&start_volume=50&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%231277a3&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=1&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D3973769%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=0&play_button_style=static&sleek_player=0&stretch=&auto_play=1&auto_play_type=unMute&floating_player=none',
          title: "Geaux Gaming",
        },
      ],
      [AGE_RATING]: 16,
    },
  ],
};
export { data };
