import React, { useContext } from 'react';
import {
  CATEGORIES,
  CATEGORY,
  ICON,
  ITEM,
  PRODUCTS_DATA,
  SUBCATEGORIES,
  SUBCATEGORY,
  TYPE_OR_COLOR,
} from 'components/Guide/ProductsData';
import { GuideContext } from '../../../../context/GuideContext';
import { RoundCheckmarkSvgIcon } from 'components/Guide/svg/general-icons/RoundCheckmarkSvgIcon';
import './Categories.styles.scss';

const Categories = () => {
  const {
    isFormModalOpen,
    formSelections,
    formOptions,
    setFormOptions,
    updateFormOptionsAndFormSelections,
    goToNextSection,
  } = useContext(GuideContext);

  const updateFormCategorySelection = (field) => {
    updateFormOptionsAndFormSelections(CATEGORY, [SUBCATEGORY, ITEM, TYPE_OR_COLOR])(field);

    const newSubcategoriesArray = PRODUCTS_DATA[field][SUBCATEGORIES].map((item) => item[SUBCATEGORY]);
    setFormOptions({ ...formOptions, [SUBCATEGORIES]: newSubcategoriesArray });
    if (newSubcategoriesArray.length > 0) goToNextSection();
  };

  return (
    <>
      <div className='gntv_categories__container'>
        <div className='gntv_categories__button-container'>
          {formOptions[CATEGORIES].map((category) => {
            return (
              <button
                key={category}
                type='button'
                className={`gntv_categories__item-button ${isFormModalOpen ? 'show' : ''}`}
                style={{
                  transitionDelay: `${isFormModalOpen ? formOptions[CATEGORIES].indexOf(category) * 100 + 100 : 200}ms`,
                }}
                onClick={() => updateFormCategorySelection(category)}
                data-active={formSelections[CATEGORY] === category}
              >
                <div className='gntv_categories__select-indicator'>
                  {formSelections[CATEGORY] === category && <RoundCheckmarkSvgIcon />}
                </div>
                {PRODUCTS_DATA[category][ICON]}
                <span className='gntv_categories__text'>{category}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Categories;
