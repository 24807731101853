import React, { useContext } from 'react';
import './NextButton.styles.scss';
import { GuideContext } from '../../../../../context/GuideContext';
import { CATEGORIES, CATEGORY, ITEM, ITEMS, SUBCATEGORIES, SUBCATEGORY } from 'components/Guide/ProductsData';
import { ChevronRightSvgIcon } from 'components/Guide/svg/general-icons/ChevronRightSvgIcon';

export const NextButton = () => {
  const { goToNextSection, currentSection, formSelections } = useContext(GuideContext);

  // Helper function to get correct formSelections key
  const mapSectionToStateKey = (section) => {
    const sections = {
      [CATEGORIES]: CATEGORY,
      [SUBCATEGORIES]: SUBCATEGORY,
      [ITEMS]: ITEM,
    };

    return sections[section] || section;
  };

  // Helper function to get correct formSelections value
  const stateKey = mapSectionToStateKey(currentSection);
  const currentStateValue = formSelections[stateKey];

  return (
    <>
      <button className='gntv_next-button__button' onClick={() => currentStateValue && goToNextSection()}>
        Next
        <ChevronRightSvgIcon />
      </button>
    </>
  );
};
