import React, { useContext } from 'react';
import { ChannelPopupContext } from '../../context/ChannelPopupContext';
import { connect } from 'react-redux';
import { data } from 'database/index';
import { getVideoId } from 'services/channelInfo';
import { Translator, Translate } from 'react-auto-translate';
import { apiKey } from '../../../config.js';

import './styles.scss';

const mapStateProps = (state) => ({
  currentVideoId: getVideoId(state),
});

const ChannelInfoOverlay = ({ currentVideoId }) => {
  const { isChannelInfoOverlayOpen, setIsChannelInfoOverlayOpen } = useContext(ChannelPopupContext);

  let channel;
  Object.values(data).some((category) => {
    const foundChannel = Object.values(category).find((channel) => channel.episodes[0].videoId === currentVideoId);
    if (foundChannel) {
      channel = foundChannel;
      return true;
    }
  });

  return (
    <>
      <div className='gn-player-overlay-info-wrapper' data-active={isChannelInfoOverlayOpen}>
        <Translator from='en' to={localStorage.getItem('language')} googleApiKey={apiKey}>
          <div className='gn-player-overlay-info-container'>
            <div className='gn-player-overlay-info__channel-container'>
              <img src={channel.channelIcon} />
              <span className='gn-player-overlay-info__channel-name'><Translate>{channel.channelName}</Translate></span>
              <span className='gn-player-overlay-info__channel-number'>{channel.number}</span>
            </div>
            <div className='gn-player-overlay-info__description'>
              <span><Translate>{channel.channelDescription}</Translate></span>
            </div>
            <button className='gn-player-close-icon' onClick={() => setIsChannelInfoOverlayOpen(false)}>
              <CloseIcon />
            </button>
          </div>
        </Translator>
      </div>
    </>
  );
};

export default connect(mapStateProps)(ChannelInfoOverlay);

const CloseIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' id='close'>
    <path d='m4 8 4-4 8 8 8-8 4 4-8 8 8 8-4 4-8-8-8 8-4-4 8-8z'></path>
  </svg>
);
