//import './dropdown-item.style.scss';
import React, { useState, useEffect, useContext, useRef } from 'react'; // added useContext and useRef

// import React from 'react';
import DropdownItem from 'components/Dropdown-item/dropdown-item.component';
import { Translator, Translate } from 'react-auto-translate';
import AccessIcon from '../../assets/icons/Sign_In_Page_Icons/wheelchair.png';

function AccessMenu(props) {
  // This code is set to open the drop down menu.  Future plan to use dropdownState instead if possible.
  const [open, setOpen] = useState(false);

  // this line is to set the current state as to if the text is already in larged or not.
  const [inLarged, setInlarged] = useState(false);
  const [pushCount, setPushCount] = useState(0);

  /** This block of code is to take the font sizes from the style sheet and set that as the initial
   * state.
   */
  const textElement = document.querySelector(':root');
  let textStyle = getComputedStyle(textElement);
  let xSmallFont = parseInt(textStyle.getPropertyValue('--fontSize_xSmall'));
  let smallFont = parseInt(textStyle.getPropertyValue('--fontSize_small'));
  let sMediumFont = parseInt(textStyle.getPropertyValue('--fontSize_sMedium'));
  let mediumFont = parseInt(textStyle.getPropertyValue('--fontSize_medium'));
  let largeFont = parseInt(textStyle.getPropertyValue('--fontSize_large'));
  let xsLargeFont = parseInt(textStyle.getPropertyValue('--fontSize_xsLarge'));
  let xLargeFont = parseInt(textStyle.getPropertyValue('--fontSize_xLarge'));
  let xxLargeFont = parseInt(textStyle.getPropertyValue('--fontSize_xxLarge'));
  let xxxLargeFont = parseInt(textStyle.getPropertyValue('--fontSize_xxxLarge'));

  /* Setting font size variables for the fontContext Provider. */
  const [fontSize, setFontSize] = useState({
    xSmall: xSmallFont,
    small: smallFont,
    smedium: sMediumFont,
    medium: mediumFont,
    large: largeFont,
    xsLarge: xsLargeFont,
    xLarge: xLargeFont,
    xxLarge: xxLargeFont,
    xxxLarge: xxxLargeFont,
  });

  function ProfileDelay() {
    /*This block of code is used to access the scss class.  This also takes into account the
   setTimeout section is set to account for the initial error of trying to access content 
   before fully loaded. */
    setTimeout(function () {
      const profileElement = document.querySelector(':root');
      profileElement.style.setProperty('--fontSize_xSmall', `${fontSize.xSmall}px`);
      profileElement.style.setProperty('--fontSize_small', `${fontSize.small}px`);
      profileElement.style.setProperty('--fontSize_smedium', `${fontSize.smedium}px`);
      profileElement.style.setProperty('--fontSize_medium', `${fontSize.medium}px`);
      profileElement.style.setProperty('--fontSize_large', `${fontSize.large}px`);
      profileElement.style.setProperty('--fontSize_xLarge', `${fontSize.xLarge}px`);
      profileElement.style.setProperty('--fontSize_xsLarge', `${fontSize.xsLarge}px`);
      profileElement.style.setProperty('--fontSize_xxLarge', `${fontSize.xxLarge}px`);
      profileElement.style.setProperty('--fontSize_xxxLarge', `${fontSize.xxxLarge}px`);
    }, 200);
  }

  if (document.readyState == 'complete') {
    ProfileDelay();
  } else {
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        ProfileDelay();
      }
    };
  }

  // Deleted since there were issues implementing this in the signin page**
  // // Permits clicking anywhere to close the accessibility menu
  // useEffect(() => {
  //   let accessibilityHandler = (e) => {
  //     const accessMenu = document.querySelector(".menu-container"); // Specifically targets the accessibility menu
  //     console.log(e.target);

  //     if (accessMenu) { // Checks that the access menu is on the page
  //       if (!accessMenu.contains(e.target)) { // Only close if an element other than the access menu is selected
  //         setOpen(false);
  //       }
  //     }
  //   }
  //   document.body.addEventListener("click", accessibilityHandler);

  //   return () => {
  //     document.body.removeEventListener("click", accessibilityHandler);
  //   }
  // })

  return (
    <div className='menu-container' /*ref ={menuRef}*/>
      <button
        className='menu-trigger'
        onClick={() => {
          setOpen(!open);
        }}
      >
        <img src={AccessIcon} alt='Wheelchair Icon' />
        <span>
          <Translate>Accessibility</Translate>
        </span>
      </button>
      <div className={`dropdown-menu  ${open ? 'active' : 'inactive'}`}>
        <br />
        <h3>
          <center>
            <Translate>Options</Translate>
          </center>
        </h3>

        {/* Dynamic imports of each function so that the code is only loaded when user utilizes one of 
           access menu options.  */}
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/increaseFonts').then((module) => {
              module.increaseFonts(setFontSize, fontSize, pushCount, setPushCount);
            })
          }
          text={'Increase text'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/decreaseFonts').then((module) => {
              module.decreaseFonts(setFontSize, fontSize, setInlarged, pushCount, setPushCount);
            })
          }
          text={'Decrease text'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/underlineLinks').then((module) => {
              module.underlineLinks();
            })
          }
          text={'Links Underline'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/readableFonts').then((module) => {
              module.readableFonts(setFontSize, fontSize, setInlarged, inLarged, setPushCount);
            })
          }
          text={'Readable Font'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/selectGrayScale').then((module) => {
              module.selectGrayScale();
            })
          }
          text={'GrayScale'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/highContrast').then((module) => {
              module.highConstrast();
            })
          }
          text={'High Contrast'}
        />
        <DropdownItem
          onClick={() =>
            import('./Acess_menu_functions/resetAttributes').then((module) => {
              module.resetAttributes(setFontSize, setInlarged, setPushCount);
            })
          }
          text={'Reset'}
        />
      </div>
    </div>
  );
}

export default AccessMenu;
