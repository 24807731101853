import React, { useContext } from 'react';
import {
  CATEGORY,
  COLORS,
  ICON,
  ITEM,
  ITEMS,
  PRODUCTS_DATA,
  SUBCATEGORIES,
  SUBCATEGORY,
  TYPE,
  TYPE_OR_COLOR,
  TYPES,
} from 'components/Guide/ProductsData';
import { GuideContext } from '../../../../context/GuideContext';
import { RoundCheckmarkSvgIcon } from 'components/Guide/svg/general-icons/RoundCheckmarkSvgIcon';
import './Items.styles.scss';

const Items = () => {
  const { formSelections, updateFormOptionsAndFormSelections, formOptions, setFormOptions, goToNextSection } =
    useContext(GuideContext);

  const updateItemFormSelection = (field) => {
    updateFormOptionsAndFormSelections(ITEM, [TYPE_OR_COLOR])(field);

    let typeOrColor = [];

    const subcategory = PRODUCTS_DATA[formSelections[CATEGORY]]?.[SUBCATEGORIES]?.find(
      (sub) => sub[SUBCATEGORY] === formSelections[SUBCATEGORY]
    );

    if (!subcategory) return setFormOptions({ ...formOptions, [TYPE_OR_COLOR]: typeOrColor });

    const selectedItem = subcategory[ITEMS]?.find((item) => item[ITEM] === field);

    if (!selectedItem) return setFormOptions({ ...formOptions, [TYPE_OR_COLOR]: typeOrColor });

    if (selectedItem[COLORS]) typeOrColor = Object.keys(selectedItem[COLORS]);

    if (selectedItem[TYPES]) typeOrColor.push(...selectedItem[TYPES].map((typeItem) => typeItem[TYPE]));

    setFormOptions({ ...formOptions, [TYPE_OR_COLOR]: typeOrColor });

    if (typeOrColor.length > 0) goToNextSection();
  };

  return (
    <>
      <div className='gntv_items__container'>
        <div className='gntv_items__button-container'>
          {formOptions[ITEMS].map((itemOption) => {
            /* prettier-ignore */
            const itemObj =
              PRODUCTS_DATA[formSelections[CATEGORY]][SUBCATEGORIES]
              .find((subcategory) => subcategory[SUBCATEGORY] === formSelections[SUBCATEGORY])[ITEMS]
              .find((item) => item[ITEM] === itemOption);

            return (
              <button
                key={itemOption}
                type='button'
                className='gntv_items__item-button'
                onClick={() => updateItemFormSelection(itemOption)}
                data-active={formSelections[ITEM] === itemOption}
              >
                <div className='gntv_items__select-indicator'>
                  {formSelections[ITEM] === itemOption && <RoundCheckmarkSvgIcon />}
                </div>
                {itemObj && itemObj[ICON]}
                <span className='gntv_items__text'>{itemOption}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Items;
