import Airtable from 'airtable';

export function postForm(userId, Channel, Category, Subcategory, Item, typeOrColor) {
  const base = new Airtable({
    // For any future Airtable operations feel free to use this Personal Access Token
    // Also at the moment this PAT is only for this particular base so in the future if you'd like to expand it to others you just need to go into Airtable,
    // select the GeauxGetIt Personal Access Token and then there'll be an option to add a base
    apiKey: 'patRf8MWnAsGXZbHi.cc6c982407ea5ebdc520cf17d243521ed9732bd9ce39b28053ad8bca81feecf0',
  }).base('app5E3zQ6dNrrySHg');

  base('GeauxGetIt form info').create(
    [
      {
        fields: {
          'User ID': userId,
          Channel: Channel,
          Category: Category,
          Subcategory: Subcategory,
          Item: Item,
          'Type/Color': typeOrColor,
          // Screenshot: screenshot,
        },
      },
    ],
    function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    }
  );
}
